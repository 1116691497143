import React, {useState} from 'react';
import {Button, CircularProgress, Container, Typography, useTheme} from '@mui/material';

import {useAuth} from '../Auth/AuthProvider';
import TransitionTypePage from './Onboarding/TransitionTypePage';
import PageHeader from '../Util/PageHeader';
import AccountHeaderIcon from '../img/account-header.svg';
import useYoungPersonTransitionTypes from '../Queries/useYoungPersonTransitionTypes';
import useYoungPersonProfileCopy from './useYoungPersonProfileCopy';
import YoungPersonDateInputPage from '../Onboarding/OnboardingPages/YoungPersonDateInputPage';
import {MAX_TRANSITION_YEAR_YP_TOOL, MIN_TRANSITION_YEAR_YP_TOOL} from '../Util/constants';
import {Link} from 'react-router-dom';

const nextYear = new Date().getFullYear() + 1;

const UpdateBigChange = () => {
  const theme = useTheme();
  const {dark, contrastText} = theme.palette.primary;

  const {updateYoungPersonProfile} = useAuth();
  const {
    isLoading: isLoadingProfileCopy,
    youngPersonProfile,
    setYoungPersonProfile,
  } = useYoungPersonProfileCopy();
  const {data: transitionTypes, isLoading: isLoadingTransitionTypes} =
    useYoungPersonTransitionTypes();

  const [isUpdating, setIsUpdating] = useState(false);
  const [screenIndex, setScreenIndex] = useState(0);

  const handleSave = async () => {
    setIsUpdating(true);
    const response = await updateYoungPersonProfile({
      id: youngPersonProfile.id,
      young_person_transition_type_id: youngPersonProfile.young_person_transition_type_id,
      leaving_month: youngPersonProfile.leaving_month,
      leaving_year: youngPersonProfile.leaving_year,
    });
    setIsUpdating(false);
    if (response.status === 200) {
      setScreenIndex(2);
    }
  };

  return (
    <>
      <PageHeader
        color={dark}
        name={'Update your Big Change'}
        icon={AccountHeaderIcon}
        textColor={contrastText}
        backTo={`/`}
      />
      <Container maxWidth="md" sx={{py: 4}}>
        {isLoadingProfileCopy || isLoadingTransitionTypes ? (
          <CircularProgress />
        ) : screenIndex === 0 ? (
          <>
            <TransitionTypePage
              transitionType={youngPersonProfile.transition_type.name}
              setTransitionType={(transitionTypeName) => {
                const newTransitionType = transitionTypes?.find(
                  (type) => type.name === transitionTypeName
                );
                setYoungPersonProfile({
                  ...youngPersonProfile,
                  transition_type: newTransitionType,
                  young_person_transition_type_id: newTransitionType.id,
                });
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              sx={{float: 'right'}}
              onClick={() => setScreenIndex(1)}>
              Next
            </Button>
          </>
        ) : screenIndex === 1 ? (
          <>
            <YoungPersonDateInputPage
              title="When will you need this help?"
              subtitle={`If you're not sure, it's OK. Just give us an estimate. This date will help Compass make sure you have all the information you need by the time you take your next big step.
    
      For example, if your big change will happen in February ${nextYear}, you should enter 2 and ${nextYear}.`}
              month={youngPersonProfile.leaving_month}
              setMonth={(month) =>
                setYoungPersonProfile({
                  ...youngPersonProfile,
                  leaving_month: month,
                })
              }
              year={youngPersonProfile.leaving_year}
              setYear={(year) =>
                setYoungPersonProfile({
                  ...youngPersonProfile,
                  leaving_year: year,
                })
              }
              minYear={MIN_TRANSITION_YEAR_YP_TOOL}
              maxYear={MAX_TRANSITION_YEAR_YP_TOOL}
              allowFutureOnly={true}
            />
            <Button
              color="secondary"
              variant="contained"
              sx={{float: 'right'}}
              disabled={isUpdating}
              onClick={handleSave}>
              Save
            </Button>
          </>
        ) : screenIndex === 2 ? (
          <>
            <Typography variant="h2" gutterBottom>
              Big Change Updated!
            </Typography>
            <Typography paragraph gutterBottom>
              You have updated your big change type and date.
            </Typography>
            <Typography paragraph gutterBottom>
              We have chosen some topics which are important for{' '}
              {youngPersonProfile.transition_type.name.toLowerCase()} that you can explore.
            </Typography>
            <Button variant="contained" color="secondary" component={Link} to="/">
              Home
            </Button>
          </>
        ) : null}
      </Container>
    </>
  );
};

export default UpdateBigChange;
