import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Link, Button, Container, Paper, Typography} from '@mui/material';

const YourPersonalData = ({yourDataContents}) => (
  <div className="center-content center-content-relative">
    <Container maxWidth="md">
      <Paper sx={{mt: 4, p: 4, borderRadius: 5}}>
        <Typography variant="h2" gutterBottom>
          Your Personal Data
        </Typography>
        {yourDataContents}
        <Typography variant="body2" paragraph>
          View Compass {` `}
          <Link component={RouterLink} to="/privacy" target="_blank" rel="noreferrer">
            Privacy Notice
          </Link>
        </Typography>
        <div className="register-buttons">
          <Button variant="outlined" color="secondary" component={RouterLink} to="/">
            Previous
          </Button>
          <Button variant="contained" color="secondary" component={RouterLink} to="/register">
            Next
          </Button>
        </div>
      </Paper>
    </Container>
  </div>
);

export default YourPersonalData;
