import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {Container} from '@mui/material';

import DialogToolbarBackButton from '../Util/DialogToolbarBackButton';

const MyTopicsDialogToolbar = ({onBackClick, closeDialog}) => {
  return (
    <Container maxWidth="md">
      <Toolbar>
        {onBackClick && <DialogToolbarBackButton onBackClick={onBackClick} />}
        <div style={{display: 'flex', flex: 1}}></div>
        <IconButton edge="start" onClick={closeDialog} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </Container>
  );
};

export default MyTopicsDialogToolbar;
