import React from 'react';

import {useYoungPersonTransition} from './YoungPersonTransitionProvider';
import MyTopicsDialog from './MyTopicsDialog';
import {TOPIC_IMPORTANCE_RATINGS} from '../Util/constants';
import {Typography} from '@mui/material';

const TentDialog = () => {
  const {ratedTentTopicsByChallenge} = useYoungPersonTransition();

  return (
    <MyTopicsDialog
      title="Topics to think about later"
      ratedTopicsByChallenge={ratedTentTopicsByChallenge}
      icon={TOPIC_IMPORTANCE_RATINGS[3].circleIcon}>
      <Typography paragraph>
        Here are topics that you said do not matter as much right now. If this is not true anymore,
        you can change your rating.
      </Typography>
    </MyTopicsDialog>
  );
};

export default TentDialog;
