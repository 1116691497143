import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Box, Button, Container, Paper, Typography} from '@mui/material';

import {useYoungPersonTransition} from './YoungPersonTransitionProvider';
import {useAuth} from '../Auth/AuthProvider';
import {TOPIC_CHALLENGE_RATINGS, TOPIC_IMPORTANCE_RATINGS, sectionIcons} from '../Util/constants';
import {YOUNG_PERSON_CATEGORY_TOPIC_ICONS} from './transitionIcons';
import PageHeader from '../Util/PageHeader';
import HowToRateTopicsDialog from './HowToRateTopicsDialog';
import {useDarkModeTheme} from '../DarkModeThemeProvider';

const TopicRatingButton = ({onClick, className, description, icon, disabled}) => (
  <Button
    disabled={disabled}
    sx={{backgroundColor: 'background.default', flex: 1, padding: 2, margin: 1, fontWeight: 'bold'}}
    onClick={onClick}
    className={`button-reset rating-button ${className}`}>
    <Typography>{description}</Typography>
    <img src={icon} alt="" />
  </Button>
);

const YoungPersonRateTransitionTopic = () => {
  const {categoryId, topicId} = useParams();
  const theme = useTheme();
  const {isDarkMode} = useDarkModeTheme();
  const navigate = useNavigate();
  const {
    categories,
    saveTopicRating,
    getNextUnratedTopicWithCurrentCategory,
    unratedCriticalTopics,
  } = useYoungPersonTransition();
  const {authState, hasRatedTopics} = useAuth();
  const nextTopicToRate = getNextUnratedTopicWithCurrentCategory(categoryId, 1);

  const [importanceRating, setImportanceRating] = useState();
  const [challengeRating, setChallengeRating] = useState();

  const [howToDialogIsVisible, setHowToDialogIsVisible] = useState(false);

  const shouldSkipTopicRatedScreen = !!authState.user?.profile?.should_skip_topic_rated_screen;
  const currentCategory = categories.find((category) => category.id === parseInt(categoryId));
  const currentTopic = currentCategory?.topics.find((topic) => topic.id === parseInt(topicId));

  useEffect(() => {
    setImportanceRating(undefined);
    setChallengeRating(undefined);
  }, [topicId]);

  const saveRating = () =>
    saveTopicRating({
      topic_id: currentTopic.id,
      importance: importanceRating,
      challenge: challengeRating,
    });

  const onSaveAndNext = async () => {
    try {
      await saveRating();
      if (shouldSkipTopicRatedScreen) {
        if (unratedCriticalTopics.length === 1) {
          // If we just rated the last critical topic
          navigate('/critical-topics');
        } else if (nextTopicToRate) {
          navigate(
            `/categories/${nextTopicToRate.transition_category_id}/topics/${nextTopicToRate.id}/rate`
          );
        } else {
          // If we just finished rating all the critical topics
          navigate(`/categories/${categoryId}/complete`);
        }
      } else {
        navigate(`/categories/${categoryId}/topics/${topicId}/rated`);
      }
    } catch {
      alert('There was a problem saving your ratings, please try again later.');
    }
  };

  const onSaveAndReturn = async () => {
    try {
      await saveRating();
      if (unratedCriticalTopics.length > 0) {
        navigate('/critical-topics');
      } else {
        navigate(`/categories/${categoryId}`);
      }
    } catch {
      alert('There was a problem saving your ratings, please try again later.');
    }
  };

  useEffect(() => {
    setHowToDialogIsVisible(!hasRatedTopics);
  }, [hasRatedTopics]);

  const subtopics = currentTopic?.subtopics ? currentTopic?.subtopics?.split('~') : [];
  const subtopicsString = subtopics?.map((subtopic) => `\n \u2022 ${subtopic}`).join(' ');
  const descriptionString = currentTopic?.description + '\n' + subtopicsString;

  return (
    <>
      <HowToRateTopicsDialog
        isVisible={howToDialogIsVisible}
        closeDialog={() => {
          setHowToDialogIsVisible(false);
          navigate(
            `/categories/${currentTopic.transition_category_id}/topics/${currentTopic.id}/rate`
          );
        }}
      />
      <PageHeader
        name={'Rate Topic'}
        color={theme.palette.yellow}
        icon={sectionIcons.ratingsIcon}
        backTo={
          unratedCriticalTopics.length > 0
            ? '/critical-topics'
            : `/categories/${currentTopic?.transition_category_id}`
        }
      />
      <Container maxWidth="md" sx={{py: 6}}>
        {currentTopic && (
          <>
            <Paper className="topic-card" sx={{borderRadius: 5}}>
              <img
                className="view-topic-icon"
                src={YOUNG_PERSON_CATEGORY_TOPIC_ICONS[currentTopic.key]}
                width={80}
                height={80}
                alt=""
              />
              <Typography variant="h3" align="center" gutterBottom>
                {currentCategory.name}
              </Typography>
              <Typography variant="h2" align="center" sx={{mb: 3}}>
                {currentTopic.name}
              </Typography>
              <Typography variant="body2" paragraph sx={{whiteSpace: 'pre-line'}}>
                {descriptionString}
              </Typography>
            </Paper>
            <Paper className="topic-card" sx={{borderRadius: 5}}>
              <Typography variant="h3" gutterBottom>
                How much does '{currentTopic.name}' matter to you right now?
              </Typography>
              <TopicRatingButton
                onClick={() => setImportanceRating(1)}
                className={`importance one ${importanceRating === 1 ? 'selected' : ''}`}
                description={TOPIC_IMPORTANCE_RATINGS[1].description}
                icon={TOPIC_IMPORTANCE_RATINGS[1].ratingButtonIcon}
              />
              <TopicRatingButton
                onClick={() => setImportanceRating(2)}
                className={`importance two ${importanceRating === 2 ? 'selected' : ''}`}
                description={TOPIC_IMPORTANCE_RATINGS[2].description}
                icon={TOPIC_IMPORTANCE_RATINGS[2].ratingButtonIcon}
              />
              <TopicRatingButton
                onClick={() => setImportanceRating(3)}
                className={`importance three ${importanceRating === 3 ? 'selected' : ''}`}
                description={TOPIC_IMPORTANCE_RATINGS[3].description}
                icon={TOPIC_IMPORTANCE_RATINGS[3].ratingButtonIcon}
              />
            </Paper>
            <Paper
              className="topic-card"
              sx={{
                borderRadius: 5,
                ...(!importanceRating ? {backgroundColor: isDarkMode ? 'black' : 'mono.pale'} : {}),
              }}>
              <Typography variant="h3" gutterBottom>
                How well do you understand '{currentTopic.name}'?
              </Typography>
              <Box display="flex">
                <TopicRatingButton
                  onClick={() => setChallengeRating(3)}
                  disabled={!importanceRating}
                  className={`challenge three ${challengeRating === 3 ? 'selected' : ''}`}
                  description={TOPIC_CHALLENGE_RATINGS[3].ratingButtonCopy}
                  icon={
                    importanceRating
                      ? TOPIC_CHALLENGE_RATINGS[3].activeButton
                      : TOPIC_CHALLENGE_RATINGS[3].inactiveButton
                  }
                />
                <TopicRatingButton
                  onClick={() => setChallengeRating(2)}
                  disabled={!importanceRating}
                  className={`challenge two ${challengeRating === 2 ? 'selected' : ''}`}
                  description={TOPIC_CHALLENGE_RATINGS[2].ratingButtonCopy}
                  icon={
                    importanceRating
                      ? TOPIC_CHALLENGE_RATINGS[2].activeButton
                      : TOPIC_CHALLENGE_RATINGS[2].inactiveButton
                  }
                />
                <TopicRatingButton
                  onClick={() => setChallengeRating(1)}
                  disabled={!importanceRating}
                  className={`challenge one ${challengeRating === 1 ? 'selected' : ''}`}
                  description={TOPIC_CHALLENGE_RATINGS[1].ratingButtonCopy}
                  icon={
                    importanceRating
                      ? TOPIC_CHALLENGE_RATINGS[1].activeButton
                      : TOPIC_CHALLENGE_RATINGS[1].inactiveButton
                  }
                />
              </Box>
            </Paper>

            <div className="right-aligned-button">
              <Button
                variant="contained"
                color="secondary"
                disabled={!importanceRating || !challengeRating}
                onClick={onSaveAndNext}>
                {shouldSkipTopicRatedScreen ? 'Save and rate another topic' : 'Save rating'}
              </Button>
            </div>

            {shouldSkipTopicRatedScreen && (
              <div className="right-aligned-button">
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={!importanceRating || !challengeRating}
                  onClick={onSaveAndReturn}>
                  Save and return to topic list
                </Button>
              </div>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default YoungPersonRateTransitionTopic;
