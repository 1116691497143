import React, {useState} from 'react';
import {Container} from '@mui/material';

import ViewPreviousOrNextCategoryButtons from './ViewPreviousOrNextCategoryButtons';
import CategoryCarouselButtons from './CategoryCarouselButtons';
import YoungPersonCategoryTile from './YoungPersonCategoryTile';

const MediumCategoryListView = ({categories, onSelectCategory}) => {
  const categoriesInPairs = [];
  for (var i = 0; i < categories.length; i += 2) {
    categoriesInPairs.push(categories.slice(i, i + 2));
  }
  const [currentCategoryPairIndex, setCurrentCategoryPairIndex] = useState(0);
  const currentCategoryPair = categoriesInPairs[currentCategoryPairIndex];

  return (
    <>
      <ViewPreviousOrNextCategoryButtons
        onPreviousClick={() => {
          if (currentCategoryPairIndex > 0) {
            setCurrentCategoryPairIndex(currentCategoryPairIndex - 1);
          }
        }}
        onNextClick={() => {
          if (currentCategoryPairIndex < categoriesInPairs.length - 1) {
            setCurrentCategoryPairIndex(currentCategoryPairIndex + 1);
          }
        }}
        previousButtonDisabled={currentCategoryPairIndex === 0}
        nextButtonDisabled={currentCategoryPairIndex === categoriesInPairs.length - 1}
      />
      <Container maxWidth="md">
        <div className="medium-category-view-container">
          {currentCategoryPair &&
            currentCategoryPair.map(
              (category) =>
                category && (
                  <YoungPersonCategoryTile
                    key={category.id}
                    category={category}
                    onSelectCategory={onSelectCategory}
                    viewMode="medium"
                  />
                )
            )}
          <CategoryCarouselButtons
            categoryArray={categoriesInPairs}
            currentIndex={currentCategoryPairIndex}
            setCurrentIndex={setCurrentCategoryPairIndex}
          />
        </div>
      </Container>
    </>
  );
};

export default MediumCategoryListView;
