import React, {useEffect, useState} from 'react';
import {Navigate, Route, useNavigate} from 'react-router-dom';
import {Button, Typography, LinearProgress, Container, Box} from '@mui/material';

import {SentryRoutes} from '../../Sentry';
import {useOnboarding} from '../../Onboarding/OnboardingProvider';
import YoungPersonDateInputPage from '../../Onboarding/OnboardingPages/YoungPersonDateInputPage';
import LocalAuthorityPage from '../../Onboarding/OnboardingPages/LocalAuthorityPage';
import TrialUnavailableInLADialog from '../../Onboarding/TrialUnavailableInLADialog';
import TransitionTypePage from './TransitionTypePage';
import {
  MIN_YP_BIRTH_YEAR,
  MAX_YP_BIRTH_YEAR,
  MIN_TRANSITION_YEAR_YP_TOOL,
  MAX_TRANSITION_YEAR_YP_TOOL,
  RESTRICT_SIGNUP_LOCAL_AUTHORITIES,
  TRIAL_LOCAL_AUTHORITIES,
  YP_ONBOARDING_ILLUSTRATIONS,
} from '../../Util/constants';
import useYoungPersonTransitionTypes from '../../Queries/useYoungPersonTransitionTypes';

const Onboarding = () => {
  const navigate = useNavigate();
  const {data: transitionTypes} = useYoungPersonTransitionTypes();
  const {submitYpOnboardingData} = useOnboarding();

  const [monthOfBirth, setMonthOfBirth] = useState('');
  const [yearOfBirth, setYearOfBirth] = useState('');
  const [livesInScotland, setLivesInScotland] = useState();
  const [localAuthority, setLocalAuthority] = useState('');
  const [transitionType, setTransitionType] = useState('');
  const [transitionMonth, setTransitionMonth] = useState('');
  const [transitionYear, setTransitionYear] = useState('');
  const [onboardingFieldPaths, setOnboardingFieldPaths] = useState([
    'intro',
    'dob',
    'local-authority',
    'transition-type',
    'transition-date',
  ]);

  const [showTrialUnavailableDialog, setShowTrialUnavailableDialog] = useState(false);

  const [currentFieldIndex, setCurrentFieldIndex] = useState(0);

  const currentFieldPath = onboardingFieldPaths[currentFieldIndex];
  const isNonFinalField = currentFieldIndex < onboardingFieldPaths.length - 1;

  const nextPageOrSubmitOnboardingData = async () => {
    if (isNonFinalField) {
      if (
        RESTRICT_SIGNUP_LOCAL_AUTHORITIES &&
        currentFieldIndex === onboardingFieldPaths.indexOf('local-authority') &&
        !TRIAL_LOCAL_AUTHORITIES.includes(localAuthority)
      ) {
        setShowTrialUnavailableDialog(true);
      } else {
        setCurrentFieldIndex(currentFieldIndex + 1);
      }
    } else {
      await submitYpOnboardingData(
        monthOfBirth,
        yearOfBirth,
        transitionYear,
        transitionMonth,
        transitionTypes.find((type) => type.name === transitionType).id,
        localAuthority
      );
      navigate('/');
    }
  };

  const nextButtonDisabled =
    (currentFieldPath === 'dob' && (monthOfBirth === '' || yearOfBirth === '')) ||
    (currentFieldPath === 'transition-date' && (transitionMonth === '' || transitionYear === '')) ||
    (currentFieldPath === 'transition-type' && transitionType === '') ||
    (currentFieldPath === 'local-authority' && localAuthority === '');

  useEffect(() => {
    const nextURL = onboardingFieldPaths[currentFieldIndex];
    if (nextURL) {
      navigate(nextURL);
    }
  }, [currentFieldIndex]);

  const nextYear = new Date().getFullYear() + 1;

  return (
    <>
      <LinearProgress
        variant="determinate"
        value={(currentFieldIndex * 100) / onboardingFieldPaths.length}
      />
      <form
        className="center-content"
        onSubmit={(event) => {
          event.preventDefault();
          nextPageOrSubmitOnboardingData();
        }}>
        <Container maxWidth="md">
          <SentryRoutes>
            <Route
              exact
              path="intro"
              element={
                <Box sx={{textAlign: 'center'}}>
                  <img src={YP_ONBOARDING_ILLUSTRATIONS.thankYou} className="screen-illustration" />
                  <Typography variant="h2" gutterBottom>
                    Thanks for signing up!
                  </Typography>
                  <Typography paragraph>
                    Now Compass will ask you questions about things that are going to happen in your
                    life as you get older.
                  </Typography>
                  <Typography paragraph>
                    Your answers show us what young people like you, in Scotland, think they need
                    help with.
                  </Typography>
                  <Typography paragraph>Compass does not tell anyone else your answers.</Typography>
                </Box>
              }
            />
            <Route
              exact
              path="dob"
              element={
                <>
                  <div className="centre-aligned">
                    <img src={YP_ONBOARDING_ILLUSTRATIONS.dob} className="screen-illustration" />
                  </div>
                  <YoungPersonDateInputPage
                    title="When were you born?"
                    subtitle={`Compass is for young people of different ages. Your ratings and answers will show us what matters to people of your age.

              If you were born in February 2007: select February, and then select 2007.`}
                    month={monthOfBirth}
                    setMonth={(month) => setMonthOfBirth(month)}
                    year={yearOfBirth}
                    setYear={(year) => setYearOfBirth(year)}
                    minYear={MIN_YP_BIRTH_YEAR}
                    maxYear={MAX_YP_BIRTH_YEAR}
                  />
                </>
              }
            />
            {/* <Route
              exact
              path="live-in-scotland"
              element={
                <>
                  <div className="centre-aligned">
                    <img
                      src={YP_ONBOARDING_ILLUSTRATIONS.localAuthority}
                      className="screen-illustration"
                    />
                  </div>
                  <LiveInScotlandPage
                    title={'Do you live in Scotland?'}
                    subtitle={
                      "If you live outside of Scotland, you can still use Compass. However, the information Compass provides might not be as helpful. Compass also won't be able to share your feedback with the people supporting you."
                    }
                    liveInScotland={liveInScotland}
                    setLiveInScotland={(newLiveInScotland) => {
                      setLiveInScotland(newLiveInScotland);
                      if (!newLiveInScotland) {
                        // if the young person doesn't live and go to school in Scotland, don't ask the user to enter
                        // their home LA or school LA
                        const newOnboardingFields = onboardingFieldPaths.filter(
                          (field) => field !== 'local-authority'
                        );
                        setOnboardingFieldPaths(newOnboardingFields);
                      }
                      nextPageOrSubmitOnboardingData();
                    }}
                    autoFocus={true}
                  />
                </>
              }
            /> */}
            <Route
              exact
              path="local-authority"
              element={
                <>
                  <div className="centre-aligned">
                    <img
                      src={YP_ONBOARDING_ILLUSTRATIONS.localAuthority}
                      className="screen-illustration"
                    />
                  </div>
                  <TrialUnavailableInLADialog
                    isVisible={showTrialUnavailableDialog}
                    closeDialog={() => setShowTrialUnavailableDialog(false)}
                  />
                  <LocalAuthorityPage
                    livesInScotlandQuestion={'Do you live in Scotland?'}
                    livesInScotlandCopy={
                      'You can still use Compass, but some of the information we provide might not apply in your area.'
                    }
                    livesInScotland={livesInScotland}
                    setLivesInScotland={setLivesInScotland}
                    title="Select the council area you live in."
                    subtitle="Compass asks this so that we know what is happening for young people in your area."
                    localAuthority={localAuthority}
                    setLocalAuthority={(newLocalAuthority) => setLocalAuthority(newLocalAuthority)}
                  />
                </>
              }
            />
            <Route
              exact
              path="transition-type"
              element={
                <>
                  <div className="centre-aligned">
                    <img
                      src={YP_ONBOARDING_ILLUSTRATIONS.bigChange}
                      className="screen-illustration"
                    />
                  </div>
                  <TransitionTypePage
                    transitionType={transitionType}
                    setTransitionType={(newTransitionType) => setTransitionType(newTransitionType)}
                  />
                </>
              }
            />
            <Route
              exact
              path="transition-date"
              element={
                <>
                  <div className="centre-aligned">
                    <img
                      src={YP_ONBOARDING_ILLUSTRATIONS.bigChangeDate}
                      className="screen-illustration"
                    />
                  </div>
                  <YoungPersonDateInputPage
                    title="When will you need this help?"
                    subtitle={`If you're not sure, it's OK. Just give us an estimate. This date will help Compass make sure you have all the information you need by the time you take your next big step.

              For example, if your big change will happen in February ${nextYear}, you should enter 2 and ${nextYear}.`}
                    month={transitionMonth}
                    setMonth={(month) => setTransitionMonth(month)}
                    year={transitionYear}
                    setYear={(year) => setTransitionYear(year)}
                    minYear={MIN_TRANSITION_YEAR_YP_TOOL}
                    maxYear={MAX_TRANSITION_YEAR_YP_TOOL}
                    allowFutureOnly={true}
                  />
                </>
              }
            />
            <Route path="*" element={<Navigate to="intro" />} />
          </SentryRoutes>
          <div className={currentFieldIndex > 0 ? 'register-buttons' : 'form-button'}>
            {currentFieldIndex > 0 && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setCurrentFieldIndex(currentFieldIndex - 1)}>
                Previous
              </Button>
            )}
            {currentFieldIndex === 0 ? (
              <Button variant="contained" color="secondary" type="submit" autoFocus>
                Continue
              </Button>
            ) : onboardingFieldPaths[currentFieldIndex] === 'live-in-scotland' ? null : (
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={nextButtonDisabled}>
                {isNonFinalField ? 'Next' : 'Finish'}
              </Button>
            )}
          </div>
        </Container>
      </form>
    </>
  );
};

export default Onboarding;
