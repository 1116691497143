import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Typography, Box, Container, Paper, Chip} from '@mui/material';

import {useEvaluation} from '../Evaluation/EvaluationProvider';
import {
  compassArrowIcons,
  longDateFormatter,
  YOUNG_PERSON_FEEDBACK_RATING_SCALE,
  FEEDBACK_STRINGS,
} from '../Util/constants';
import PreviousEvaluationQuestionResponse from './PreviousEvaluationQuestionResponse';
import EvaluationHistoryIcon from '../img/EvaluationHistoryLight.svg';
import PageHeader from '../Util/PageHeader';

const PreviousEvaluation = () => {
  const theme = useTheme();
  const {evaluationId} = useParams();

  const {evaluationState, fetchHistory} = useEvaluation();
  const [currentEvaluation, setCurrentEvaluation] = useState();
  const [selectedThemeName, setSelectedThemeName] = useState();
  const [responseSummaryByRating, setResponseSummaryByRating] = useState();

  useEffect(() => {
    const fetchEvaluationHistory = async () => await fetchHistory();
    fetchEvaluationHistory();
  }, []);

  useEffect(() => {
    if (evaluationState.evaluations) {
      setCurrentEvaluation(
        evaluationState.evaluations.find((evaluation) => evaluation.id.toString() === evaluationId)
      );
    }
    if (evaluationState.responsesByThemeName) {
      let allRatings = [];
      Object.keys(evaluationState.responsesByThemeName).map((themeName) =>
        evaluationState.responsesByThemeName[themeName][evaluationId].map((response) =>
          allRatings.push(response.response_rating)
        )
      );
      setResponseSummaryByRating({
        1: allRatings.filter((rating) => rating === 1).length,
        2: allRatings.filter((rating) => rating === 2).length,
        3: allRatings.filter((rating) => rating === 3).length,
      });
    }
  }, [evaluationState]);

  return (
    <>
      <PageHeader
        color={theme.palette.pink}
        name={FEEDBACK_STRINGS.youngPerson.feedbackHistoryTitle}
        icon={EvaluationHistoryIcon}
        backTo="/feedback/history"
      />
      <Container maxWidth="md" sx={{py: 4}}>
        {currentEvaluation && (
          <div className="previous-eval-info">
            <Typography variant="h3" gutterBottom>
              {longDateFormatter.format(new Date(currentEvaluation.completed_at))}
            </Typography>
          </div>
        )}

        <Paper
          className="previous-eval-summary-container body2"
          sx={{borderRadius: 5, py: 3, px: 4}}>
          <Typography paragraph>Here is a summary of how you rated these questions:</Typography>
          <div className="thumb-rating-summary-row">
            {responseSummaryByRating &&
              Object.entries(YOUNG_PERSON_FEEDBACK_RATING_SCALE).map(
                ([ratingKey, {value, icon}]) => {
                  const barHeight =
                    (responseSummaryByRating[value] /
                      Math.max(...Object.values(responseSummaryByRating))) *
                    100;
                  return (
                    <div className="thumb-rating-summary-container" key={ratingKey}>
                      <Chip
                        label={<Typography>{responseSummaryByRating[value]}</Typography>}
                        variant="outlined"
                      />
                      <div className="thumb-bar-container">
                        <div
                          className={`thumb-bar-fill ${ratingKey.toLowerCase().replace(' ', '-')}`}
                          style={{
                            height: parseInt(barHeight),
                          }}></div>
                      </div>
                      <Box component="img" aria-hidden="true" sx={{m: 2}} src={icon} height={45} />
                      <Typography>{ratingKey}</Typography>
                    </div>
                  );
                }
              )}
          </div>
        </Paper>

        <h3 className="subtitle1 previous-eval-section-title">Full Answers</h3>
        {evaluationState.responsesByThemeName ? (
          Object.keys(evaluationState.responsesByThemeName).map((themeName) => {
            return (
              <div
                key={themeName}
                onClick={() => {
                  if (selectedThemeName !== themeName) {
                    setSelectedThemeName(themeName);
                  } else {
                    setSelectedThemeName(undefined);
                  }
                }}>
                <button className="button-reset previous-eval-theme">
                  <div className="subtitle1 dark-grey">{themeName}</div>
                  {selectedThemeName === themeName ? (
                    <img src={compassArrowIcons.collapseIcon.mono} />
                  ) : (
                    <img src={compassArrowIcons.expandIcon.mono} />
                  )}
                </button>

                {selectedThemeName === themeName &&
                  evaluationState.responsesByThemeName[themeName][evaluationId].map((response) => (
                    <PreviousEvaluationQuestionResponse key={response.id} response={response} />
                  ))}
              </div>
            );
          })
        ) : (
          <div>Loading...</div>
        )}
        {currentEvaluation?.other_comments && (
          <Box sx={{p: 2, mb: 2}}>
            <Typography variant="title">You also said...</Typography>
            <Typography variant="subtitle1">"{currentEvaluation?.other_comments}"</Typography>
          </Box>
        )}
      </Container>
    </>
  );
};

export default PreviousEvaluation;
