import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Container} from '@mui/material';

import {useEvaluation} from './EvaluationProvider';
import NavButton from '../Util/NavButton';
import feedbackIcon from '../img/EvaluationLight.svg';
import feedbackHistoryIcon from '../img/EvaluationHistoryLight.svg';
import {useAuth} from '../Auth/AuthProvider';

const FeedbackHomeScreen = ({
  pageHeader,
  feedbackColor,
  feedbackButtonTitle,
  feedbackHistoryTitle,
}) => {
  const navigate = useNavigate();
  const {evaluationState, isLoading, fetchHistory} = useEvaluation();
  const {isParent} = useAuth();

  const handleStart = async () => {
    if (evaluationState.evaluations?.length > 0 || isParent) {
      navigate('/feedback/about');
    } else {
      navigate('/feedback/about-you');
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  return (
    <>
      {pageHeader}
      <Container maxWidth="md">
        <NavButton
          disabled={isLoading}
          onClick={handleStart}
          title={feedbackButtonTitle}
          backgroundColor={feedbackColor}
          icon={feedbackIcon}
        />
        <NavButton
          disabled={isLoading}
          onClick={() => navigate('/feedback/history')}
          title={feedbackHistoryTitle}
          backgroundColor={feedbackColor}
          icon={feedbackHistoryIcon}
        />
      </Container>
    </>
  );
};

export default FeedbackHomeScreen;
