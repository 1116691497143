import React from 'react';
import {Typography} from '@mui/material';

import {TOPIC_CHALLENGE_RATINGS} from '../Util/constants';
import SummaryChallengeSection from './SummaryChallengeSection';

const SummaryImportanceSection = ({ratedTopicsByChallenge, textIfEmpty}) => {
  const sectionHasTopics = Object.values(ratedTopicsByChallenge).flat().length > 0;
  return sectionHasTopics ? (
    <div className="summary-section">
      {Object.entries(ratedTopicsByChallenge)
        .reverse()
        .map(([rating, topics]) => (
          <SummaryChallengeSection
            key={rating}
            topicsForChallengeRating={topics}
            icon={TOPIC_CHALLENGE_RATINGS[rating].icon}
            subtitle={`Topics you ${TOPIC_CHALLENGE_RATINGS[rating].summary}`}
          />
        ))}
    </div>
  ) : (
    <Typography paragraph>{textIfEmpty}</Typography>
  );
};

export default SummaryImportanceSection;
