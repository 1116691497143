import React from 'react';

import {
  compassArrowIcons,
  TOPIC_IMPORTANCE_RATINGS,
  TOPIC_CHALLENGE_RATINGS,
} from '../Util/constants';
import {YOUNG_PERSON_CATEGORY_TOPIC_ICONS} from './transitionIcons';
import {Box, Card, CardActionArea, Typography} from '@mui/material';

const TransitionTopicTile = ({onClick, topic, onClickLabel}) => {
  return (
    <Card
      className="topic"
      onClick={onClick}
      sx={{
        borderRadius: 5,
      }}>
      <CardActionArea
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}>
        <Box display="flex" justifyContent="space-between" sx={{mb: 1, width: '100%'}}>
          <img src={YOUNG_PERSON_CATEGORY_TOPIC_ICONS[topic.key]} width={46} height={46} />
          {topic.rating && (
            <Box>
              <img
                style={{margin: 3}}
                src={TOPIC_IMPORTANCE_RATINGS[topic.rating.importance_rating].icon}
                height={25}
              />
              <img
                style={{margin: 3}}
                src={TOPIC_CHALLENGE_RATINGS[topic.rating.challenge_rating].icon}
                height={25}
              />
            </Box>
          )}
        </Box>
        <Typography variant="body2" sx={{flex: 1}} gutterBottom>
          {topic.name}
        </Typography>
        <Box display="flex" alignSelf={'flex-end'}>
          <Typography variant="body2" sx={{mr: 1}}>
            {onClickLabel}
          </Typography>
          <img src={compassArrowIcons.rightFacingArrowIcon.secondary} />
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default TransitionTopicTile;
