import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

const useYoungPersonTransitionTypes = () => {
  const {data, isLoading, error} = useQuery({
    queryKey: ['young-person-transition-types'],
    queryFn: async () =>
      await axios.get('/api/onboarding/transition-types').then((res) => res.data),
    staleTime: Infinity,
  });

  return {data, isLoading, error};
};

export default useYoungPersonTransitionTypes;
