import React from 'react';
import {Box, Typography, Checkbox, FormControlLabel} from '@mui/material';

import CustomInputs from '../../Util/CustomInputs';

const AdditionalSupportNeedsPage = ({
  heading,
  caption,
  additionalSupportNeeds,
  selectedAdditionalSupportNeeds,
  toggleAdditionalSupportNeed,
  customAdditionalSupportNeeds,
  setCustomAdditionalSupportNeeds,
  otherLabel,
}) => (
  <>
    <Typography variant="h3" gutterBottom>
      {heading}
    </Typography>
    <Typography paragraph variant="body2">
      Your selection(s) won't change the information Compass will give you, but will help to improve
      the transitions process.
    </Typography>
    <Typography paragraph variant="body2">
      {caption}
    </Typography>
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      {additionalSupportNeeds.map((need) => (
        <FormControlLabel
          key={need.name}
          label={need.name}
          control={
            <Checkbox
              value={need.name}
              checked={
                !!selectedAdditionalSupportNeeds.find(
                  (youngPersonNeed) => youngPersonNeed.name === need.name
                )
              }
              color="secondary"
              onChange={(event) => toggleAdditionalSupportNeed(need, event.target.checked)}
              // TODO: reinstate autofocus after upgrade to MUI v5 (causes problems in v4 with React 18)
              // autoFocus={index === 0}
            />
          }
        />
      ))}
      <Typography variant="h4" sx={{mt: 2}}>
        {otherLabel}
      </Typography>
      <CustomInputs
        // The empty string is used to render the empty custom input field
        // Without it, the input field needs to be manually added
        values={customAdditionalSupportNeeds?.length === 0 ? [''] : customAdditionalSupportNeeds}
        setValues={setCustomAdditionalSupportNeeds}
      />
    </Box>
  </>
);

export default AdditionalSupportNeedsPage;
