import React from 'react';
import {Box, Paper, Typography} from '@mui/material';

import {YOUNG_PERSON_FEEDBACK_RATING_SCALE} from '../Util/constants';

const EvaluationQuestion = ({question, response, setResponse}) => {
  return (
    <div className="eval-question">
      <Paper sx={{borderRadius: 5, py: 2, px: 6, width: '100%'}}>
        <div className="eval-item">
          <Typography variant="h4" gutterBottom>
            {question.title}
          </Typography>
          <Typography variant="body2" paragraph>
            Select one of the options.
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'space-around'}} className="eval-item-thumbs">
            {Object.entries(YOUNG_PERSON_FEEDBACK_RATING_SCALE).map(([ratingKey, ratingValue]) => {
              return (
                <button
                  className="button-reset"
                  key={ratingKey}
                  onClick={() => setResponse({...response, rating: ratingValue.value})}>
                  <img
                    className={`eval-thumb-button-icon ${
                      response.rating === ratingValue.value ? ' selected' : ''
                    }`}
                    src={
                      response.rating === ratingValue.value
                        ? ratingValue.activeIcon
                        : ratingValue.inactiveIcon
                    }
                    height={60}
                  />
                  <div className="body2">{ratingKey}</div>
                </button>
              );
            })}
          </Box>
        </div>
      </Paper>
    </div>
  );
};

export default EvaluationQuestion;
