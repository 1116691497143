import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Box, Button, Container, Paper, Typography} from '@mui/material';

import {useYoungPersonTransition} from './YoungPersonTransitionProvider';
import {sectionIcons, YOUNG_PERSON_CATEGORY_ICONS} from '../Util/constants';
import PageHeader from '../Util/PageHeader';
import CategoryState from './CategoryState';
import allTopicsRatedIcon from '../img/complete-tick-primary-filled.svg';

const YoungPersonTransitionCategoryComplete = () => {
  const {categoryId} = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const {categories, unsureTopics} = useYoungPersonTransition();

  const currentCategory = categories.find((category) => category.id === parseInt(categoryId));

  const categoriesWithUnratedTopics = categories.filter((category) => {
    const unratedTopicsinCategory = category.topics.filter((topic) => !topic.rating);
    return unratedTopicsinCategory.length !== 0;
  });

  const handleRateAnotherCategory = () => {
    // Move onto the next category with unrated topics
    // And select an unrated topic as the new topic
    const newCategory = categoriesWithUnratedTopics[0];
    const newCategoryUnratedTopics = newCategory.topics.filter((topic) => !topic.rating);
    const newTopic = newCategoryUnratedTopics[0];
    navigate(`/categories/${newCategory.id}/topics/${newTopic.id}/rate`);
  };

  const handleReturnToCategoryList = async () => {
    navigate(`/categories`);
  };

  const unsureTopicsInCurrentCategory = unsureTopics.filter(
    (topic) => topic.transition_category_id === currentCategory.id
  );

  return (
    <>
      <PageHeader
        name={'Rate Topic'}
        color={theme.palette.yellow}
        icon={sectionIcons.ratingsIcon}
        backTo={`/categories/${categoryId}`}
      />
      <Container maxWidth="md" sx={{py: 6}}>
        <Typography variant="h2" gutterBottom>
          You have rated all the topics in this category!
        </Typography>

        <Paper sx={{borderRadius: 5, mb: 2, p: 4}}>
          <CategoryState icon={allTopicsRatedIcon} text="Rated" />
          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Typography variant="h3" gutterBottom>
              {currentCategory?.name}
            </Typography>
            <img src={YOUNG_PERSON_CATEGORY_ICONS[currentCategory?.key]} width={150} height={150} />
          </Box>
        </Paper>
        <Box sx={{mb: 2}}>
          {categoriesWithUnratedTopics.length > 0 ? (
            <>
              {unsureTopicsInCurrentCategory?.length > 0 && (
                <Typography paragraph>
                  You said you were not sure about {unsureTopicsInCurrentCategory.length} topics in
                  this category. If your situation changes, you can update these ratings at any
                  time.
                </Typography>
              )}
              <Typography paragraph>
                If you want to continute rating topics, a new category will be selected for you at
                random.
              </Typography>
              <Typography paragraph>
                You can also return to the list of categories if you want continue exploring other
                helpful topics.
              </Typography>
            </>
          ) : (
            <>
              <Typography paragraph>
                You have no remaining topics to rate in any category.
              </Typography>
              {unsureTopics.length > 0 && (
                <Typography paragraph>
                  Overall, there {unsureTopics.length === 1 ? 'was' : 'were'} {unsureTopics.length}{' '}
                  topic{unsureTopics.length > 1 ? 's' : ''} you said you were not sure about.
                </Typography>
              )}
              <Typography paragraph>
                If your situation changes, you can update these ratings at any time.
              </Typography>
            </>
          )}
        </Box>
        {categoriesWithUnratedTopics.length > 0 && (
          <div className="right-aligned-button">
            <Button variant="contained" color="secondary" onClick={handleRateAnotherCategory}>
              Rate another category
            </Button>
          </div>
        )}
        <div className="right-aligned-button">
          <Button variant="outlined" color="secondary" onClick={handleReturnToCategoryList}>
            Return to category list
          </Button>
        </div>
      </Container>
    </>
  );
};

export default YoungPersonTransitionCategoryComplete;
