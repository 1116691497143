import React from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Button, Container, Typography, Link} from '@mui/material';

import {useEvaluation} from '../Evaluation/EvaluationProvider';
import PageHeader from '../Util/PageHeader';
import {FEEDBACK_STRINGS} from '../Util/constants';
import feedbackIcon from '../img/EvaluationLight.svg';

const YoungPersonEvaluationAbout = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {isLoading, checkHasActiveEvaluation, createNewEvaluation} = useEvaluation();
  const {feedbackTitle, feedbackButtonTitle} = FEEDBACK_STRINGS.youngPerson;

  const handleContinue = async () => {
    const hasExistingEvaluation = await checkHasActiveEvaluation();
    if (!hasExistingEvaluation) {
      await createNewEvaluation();
    }
    navigate('/feedback/new');
  };

  return (
    <>
      <PageHeader
        color={theme.palette.pink}
        name={feedbackTitle}
        icon={feedbackIcon}
        backTo="/feedback"
      />
      <div className="center-content">
        <Container maxWidth="md">
          <Typography variant="h2" gutterBottom>
            {feedbackButtonTitle}
          </Typography>
          <Typography paragraph>
            Compass will ask you some questions to help you and the people supporting you. Please
            answer them as honestly as you can.
          </Typography>
          <Typography paragraph>
            Your answers are anonymous, which means no one will know you gave them. You can read our{' '}
            <Link component={RouterLink} to="/privacy" target="_blank" rel="noreferrer">
              Privacy Notice
            </Link>{' '}
            if you would like to know how your answers are used.
          </Typography>
          <Button
            disabled={isLoading}
            variant="contained"
            color="secondary"
            onClick={handleContinue}
            style={{float: 'right'}}>
            Continue
          </Button>
        </Container>
      </div>
    </>
  );
};

export default YoungPersonEvaluationAbout;
