import React from 'react';
import {useNavigate, Link as RouterLink} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import {Box, Container, Link, ListItemButton, Typography} from '@mui/material';

import PageHeader from '../Util/PageHeader';
import AccountHeaderIcon from '../img/account-header.svg';
import {useAuth} from '../Auth/AuthProvider';
import {useOnboarding} from '../Onboarding/OnboardingProvider';
import {
  MONTHS,
  YOUNG_PERSON_DISABILITY_CHALLENGE_LEVEL,
  YP_ACCOUNT_SUMMARY_DOWNLOAD_LINK_PDF,
} from '../Util/constants';
import AccountItem from '../Account/AccountItem';
import MyAccountDetails from '../Account/MyAccountDetails';
import DownloadIcon from '@mui/icons-material/Download';
import MyAccountCookieToggle from '../Util/MyAccountCookieToggle';

const Account = () => {
  const {authState} = useAuth();
  const {onboardingState} = useOnboarding();
  const navigate = useNavigate();
  const theme = useTheme();
  const {dark, contrastText} = theme.palette.primary;
  const hasLoaded = authState.user.id !== undefined;
  return hasLoaded ? (
    <>
      <PageHeader
        color={dark}
        name={'My Account'}
        icon={AccountHeaderIcon}
        textColor={contrastText}
      />
      <Container maxWidth="md" sx={{py: 6}}>
        <Typography paragraph>
          Here is all the personal information you shared with Compass when you signed up. You can
          change this at any time.
        </Typography>
        <Typography paragraph>
          If you want to know more about how we use your information, you can read our{' '}
          <Link component={RouterLink} to="/privacy">
            Privacy Notice
          </Link>
          .
        </Typography>
        <Box sx={{mb: 3}}>
          <Typography variant="h3" component="h2" gutterBottom>
            My Information
          </Typography>
          <MyAccountDetails user={authState.user} />
        </Box>
        <Box sx={{mb: 3}}>
          <Typography variant="h3" component="h2" gutterBottom>
            Information About Me
          </Typography>
          <AccountItem heading="When were you born?" onClick={() => navigate('edit/dob')}>
            <Typography variant="body2">{`${MONTHS[authState.user?.profile?.birth_month]}, ${
              authState.user?.profile?.birth_year
            }`}</Typography>
          </AccountItem>
          <AccountItem heading="Local council area:" onClick={() => navigate('edit/local-council')}>
            <Typography variant="body2">
              {onboardingState.localAuthorities?.find(
                (localAuthorityOption) =>
                  localAuthorityOption.id === authState.user?.profile?.local_authority_id
              )?.name ?? 'Outside Scotland'}
            </Typography>
          </AccountItem>
          <AccountItem heading="Next step:" onClick={() => navigate('edit/big-change')}>
            <Typography variant="body2">{authState.user.profile.transition_type.name}</Typography>
          </AccountItem>
          <AccountItem
            heading="When will the change happen?"
            onClick={() => navigate('edit/big-change-date')}>
            <Typography variant="body2">{`${MONTHS[authState.user?.profile?.leaving_month]}, ${
              authState.user?.profile?.leaving_year
            }`}</Typography>
          </AccountItem>
          <AccountItem
            heading="Do you identify as having additional support needs or disability?"
            onClick={() => navigate('edit/identify')}>
            <Typography variant="body2">
              {authState.user?.profile?.identifies_as_disabled ?? 'You did not give an answer'}
            </Typography>
          </AccountItem>
          <AccountItem
            heading="How challenging does your additional support need or disability make life for you?"
            onClick={() => navigate('edit/challenge-level')}>
            <Typography variant="body2">
              {YOUNG_PERSON_DISABILITY_CHALLENGE_LEVEL[authState.user?.profile?.challenge_level] ??
                'You did not give an answer'}
            </Typography>
          </AccountItem>
          <AccountItem
            heading="What is the nature of your additional support need or disability?"
            onClick={() => navigate('edit/additional-support-needs')}>
            {authState.user?.profile?.additional_support_need_categories.length === 0 &&
            authState.user?.profile?.custom_additional_support_need_categories.length === 0 ? (
              <Typography variant="body2">You did not give an answer</Typography>
            ) : (
              <>
                {authState.user?.profile?.additional_support_need_categories.map((item) => (
                  <div key={`additional-need-${item.id}`} className="body2">
                    {item.name}
                  </div>
                ))}
                {authState.user?.profile?.custom_additional_support_need_categories.map(
                  (item, index) => (
                    <div key={`custom-additional-need-${index}`} className="body2">
                      {item.name}
                    </div>
                  )
                )}
              </>
            )}
          </AccountItem>
          <ListItemButton href={YP_ACCOUNT_SUMMARY_DOWNLOAD_LINK_PDF} download sx={{p: 2}} divider>
            <Box sx={{flex: 1}}>
              <Typography color="secondary">Export my account</Typography>
            </Box>
            <DownloadIcon color="secondary" />
          </ListItemButton>
        </Box>
        <MyAccountCookieToggle />
      </Container>
    </>
  ) : (
    <div className="center-content">
      <div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <CircularProgress size={100} color="secondary" />
      </div>
    </div>
  );
};

export default Account;
