import React from 'react';

import YoungPersonCategoryTile from './YoungPersonCategoryTile';

const AllCategoriesListView = ({categories, onSelectCategory}) => (
  <>
    {categories.map((category) => (
      <YoungPersonCategoryTile
        key={category.id}
        category={category}
        onSelectCategory={onSelectCategory}
        viewMode="all"
      />
    ))}
  </>
);

export default AllCategoriesListView;
