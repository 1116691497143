import React from 'react';
import {Typography} from '@mui/material';

import YourPersonalData from '../YourPersonalData';

const YoungPersonYourPersonalDataScreen = () => (
  <YourPersonalData
    yourDataContents={
      <>
        <Typography paragraph>
          We might share some of the information you give us with people who are trying to make
          things better for young people in Scotland.
        </Typography>
        <Typography paragraph>
          We add this information to what other young people have said, so no one will be able to
          tell it was you who gave it.
        </Typography>
        <Typography paragraph>
          If you want to know more about this, you can read our privacy notice (link opens in a new
          tab).
        </Typography>
      </>
    }
  />
);

export default YoungPersonYourPersonalDataScreen;
