import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Container, TextField} from '@mui/material';

import {useEvaluation} from './EvaluationProvider';

const EvaluationOtherComments = ({pageHeader, content}) => {
  const navigate = useNavigate();
  const {saveComments} = useEvaluation();
  const [comments, setComments] = useState('');
  return (
    <>
      {pageHeader}
      <Container maxWidth="md" sx={{py: 6}}>
        {content}
        <TextField
          id="other-comments"
          label="Comments (optional)"
          multiline
          rows={4}
          value={comments}
          onChange={(e) => setComments(e.target.value.slice(0, 500))}
          variant="outlined"
          sx={{width: '100%', mb: 2}}
          helperText={`${comments.length}/500 characters`}
        />
        <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
          <Button
            color="secondary"
            variant="contained"
            sx={{ml: 'auto', display: 'inline'}}
            onClick={async () => {
              try {
                await saveComments(comments);
                navigate('/feedback/complete');
              } catch (e) {
                alert(e.response?.data?.message || 'Something went wrong.');
              }
            }}>
            Submit
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default EvaluationOtherComments;
