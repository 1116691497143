import React, {useEffect, useState} from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import {Button, IconButton} from '@mui/material';

const CustomInputs = ({values, setValues}) => {
  const [customValues, setCustomValues] = useState(values);

  const addNewValue = () => {
    setCustomValues([...customValues, '']);
  };
  const setValueAtIndex = (value, index) => {
    let newValues = [...customValues];
    newValues[index] = value;
    setCustomValues(newValues);
  };
  const removeValueAtIndex = (index) => {
    if (customValues.length === 1) {
      setValueAtIndex('', 0);
    } else {
      let newValues = [...customValues];
      newValues.splice(index, 1);
      setCustomValues(newValues);
    }
  };

  useEffect(() => {
    setValues(customValues.filter((value) => value !== ''));
  }, [customValues]);

  const lastValueIsBlank = customValues[customValues.length - 1] === '';
  const deleteButtonIsDisabled = customValues.length === 1 && customValues[0] === '';

  return (
    <>
      {customValues &&
        customValues.map((value, index) => (
          <div className="custom-inputs-input" key={index}>
            <input
              type="text"
              value={value}
              autoFocus={false}
              onChange={(event) => setValueAtIndex(event.target.value, index)}
            />
            <IconButton
              color="secondary"
              onClick={() => removeValueAtIndex(index)}
              disabled={deleteButtonIsDisabled}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      <div className="right-aligned-button">
        <Button
          type="button"
          variant="outlined"
          color="secondary"
          onClick={addNewValue}
          disabled={lastValueIsBlank}
          endIcon={<AddIcon />}>
          Add
        </Button>
      </div>
    </>
  );
};
export default CustomInputs;
