import React, {useState} from 'react';
import {
  Dialog,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
  DialogActions,
  Button,
} from '@mui/material';

import {onCloseUnlessBackdropClick} from '../Util/helpers';
import {useAuth} from '../Auth/AuthProvider';
import {CONTACT_EMAIL_ADDRESS} from '../Util/constants';

const TrialUnavailableInLADialog = ({isVisible, closeDialog}) => {
  const {permanentlyDeleteUser} = useAuth();
  const [shouldShowLoadingIndicator, setShouldShowLoadingIndicator] = useState(false);
  const handleDeleteAccount = async () => {
    setShouldShowLoadingIndicator(true);
    await permanentlyDeleteUser();
    setShouldShowLoadingIndicator(false);
  };
  return (
    <Dialog
      open={isVisible}
      onClose={(event, reason) => onCloseUnlessBackdropClick(event, reason, closeDialog)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>Not Currently Available</DialogTitle>
      <DialogContent>
        {shouldShowLoadingIndicator ? (
          <>
            <div className="header-4">Deleting User...</div>
            <div
              style={{
                paddingTop: 12,
              }}>
              <CircularProgress size={100} color="secondary" />
            </div>
          </>
        ) : (
          <>
            <Typography paragraph>
              Compass is currently being trialled and is not yet available in your selected local
              authority.
            </Typography>
            <Typography paragraph>
              We are working hard to ensure the application is the best it can be before it is
              released. Please do not sign up unless you are in a selected trial area.
            </Typography>
            <Typography paragraph>
              Information you entered during sign up will be deleted.
            </Typography>
            <Typography paragraph>
              If you have any concerns please contact us at{' '}
              <Link href={`mailto:${CONTACT_EMAIL_ADDRESS}`} target="_blank" rel="noreferrer">
                {CONTACT_EMAIL_ADDRESS}
              </Link>
            </Typography>
            <DialogActions>
              <Button variant="outlined" color="secondary" onClick={handleDeleteAccount}>
                Exit Sign Up and Delete my Account
              </Button>
            </DialogActions>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TrialUnavailableInLADialog;
