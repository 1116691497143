import React, {useState} from 'react';

import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import {useTheme} from '@mui/material/styles';

import TransitionTopicTile from './TransitionTopicTile';
import {TOPIC_CHALLENGE_RATINGS} from '../Util/constants';
import {capitalizeFirstLetter} from '../Util/helpers';
import RatedTopicDetails from './RatedTopicDetails';
import MyTopicsDialogToolbar from './MyTopicsDialogToolbar';
import {DialogTransition} from '../Util/helpers';
import DialogSectionHeaderButton from './DialogSectionHeaderButton';
import useDialogQuery from '../Util/useDialogQuery';
import {Container, Paper, Typography} from '@mui/material';

const MyTopicsDialog = ({title, ratedTopicsByChallenge, icon, children}) => {
  const theme = useTheme();
  const {closeDialog} = useDialogQuery();
  const {pale, light, base} = theme.palette.notSure;

  const [veryChallengingTopicsAreVisible, setVeryChallengingTopicsAreVisible] = useState(true);
  const [kindOfChallengingTopicsAreVisible, setKindOfChallengingTopicsAreVisible] = useState(true);
  const [notChallengingTopicsAreVisible, setNotChallengingTopicsAreVisible] = useState(true);
  const [currentTopic, setCurrentTopic] = useState();

  const onClose = () => {
    closeDialog();
    setCurrentTopic(undefined);
  };

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={onClose}
      TransitionComponent={DialogTransition}
      PaperProps={{
        sx: {backgroundColor: 'background.default'},
      }}>
      <MyTopicsDialogToolbar
        onBackClick={currentTopic ? () => setCurrentTopic(undefined) : undefined}
        closeDialog={onClose}
      />
      <Container maxWidth="md">
        <div className="dialog-header">
          <img src={icon} alt="" />
          <Typography variant="h2" component="h1">
            {title}
          </Typography>
        </div>
        {currentTopic ? (
          <>
            <Divider aria-hidden="true" />
            <RatedTopicDetails topic={currentTopic} beforeUpdateButtonPress={onClose} />
          </>
        ) : (
          <>
            <Paper sx={{mb: 4, p: 2, borderRadius: 5}}>
              {children}
              <Typography paragraph>Select a topic to find out more about it.</Typography>
            </Paper>
            <Typography variant="h3" gutterBottom>
              Topics that you...
            </Typography>
            <DialogSectionHeaderButton
              onClick={() => setVeryChallengingTopicsAreVisible(!veryChallengingTopicsAreVisible)}
              colour={pale}
              icon={TOPIC_CHALLENGE_RATINGS[3].icon}
              title={capitalizeFirstLetter(TOPIC_CHALLENGE_RATINGS[3].summary)}
              sectionContentsAreVisible={veryChallengingTopicsAreVisible}
            />
            {veryChallengingTopicsAreVisible && (
              <div className="dialog-section-container">
                {ratedTopicsByChallenge[3].length ? (
                  <div className="topics-container">
                    {ratedTopicsByChallenge[3]?.map((topic) => (
                      <TransitionTopicTile
                        onClick={() => setCurrentTopic(topic)}
                        topic={topic}
                        key={topic?.id}
                        onClickLabel="View topic"
                      />
                    ))}
                  </div>
                ) : (
                  <Typography paragraph>
                    You have not said which topics you {TOPIC_CHALLENGE_RATINGS[3].summary}
                  </Typography>
                )}
              </div>
            )}

            <DialogSectionHeaderButton
              onClick={() =>
                setKindOfChallengingTopicsAreVisible(!kindOfChallengingTopicsAreVisible)
              }
              colour={light}
              icon={TOPIC_CHALLENGE_RATINGS[2].icon}
              title={capitalizeFirstLetter(TOPIC_CHALLENGE_RATINGS[2].summary)}
              sectionContentsAreVisible={kindOfChallengingTopicsAreVisible}
            />
            {kindOfChallengingTopicsAreVisible && (
              <div className="dialog-section-container">
                {ratedTopicsByChallenge[2].length ? (
                  <div className="topics-container">
                    {ratedTopicsByChallenge[2]?.map((topic) => (
                      <TransitionTopicTile
                        onClick={() => setCurrentTopic(topic)}
                        topic={topic}
                        key={topic?.id}
                        onClickLabel="View topic"
                      />
                    ))}
                  </div>
                ) : (
                  <Typography paragraph>
                    You have not said which topics you {TOPIC_CHALLENGE_RATINGS[2].summary}
                  </Typography>
                )}
              </div>
            )}

            <DialogSectionHeaderButton
              onClick={() => setNotChallengingTopicsAreVisible(!notChallengingTopicsAreVisible)}
              colour={base}
              icon={TOPIC_CHALLENGE_RATINGS[1].icon}
              title={capitalizeFirstLetter(TOPIC_CHALLENGE_RATINGS[1].summary)}
              sectionContentsAreVisible={notChallengingTopicsAreVisible}
            />
            {notChallengingTopicsAreVisible && (
              <div className="dialog-section-container">
                {ratedTopicsByChallenge[1].length ? (
                  <div className="topics-container">
                    {ratedTopicsByChallenge[1]?.map((topic) => (
                      <TransitionTopicTile
                        onClick={() => setCurrentTopic(topic)}
                        topic={topic}
                        key={topic?.id}
                        onClickLabel="View topic"
                      />
                    ))}
                  </div>
                ) : (
                  <Typography paragraph>
                    You have not said which topics you {TOPIC_CHALLENGE_RATINGS[1].summary}
                  </Typography>
                )}
              </div>
            )}
          </>
        )}
      </Container>
    </Dialog>
  );
};

export default MyTopicsDialog;
