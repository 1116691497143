import React from 'react';
import {Link} from 'react-router-dom';
import {Box, Button, Card, Typography, useTheme} from '@mui/material';

const HomeFeedbackCard = ({iconSrc, feedbackTitle, description, feedbackButtonTitle}) => {
  const theme = useTheme();
  const {feedbackColor} = theme.palette;
  return (
    <Card
      sx={{
        backgroundColor: feedbackColor,
        borderRadius: 7,
        mb: 4,
        py: 3,
        px: 2,
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
      }}>
      <Box id="feedback-card-icon-container" sx={{alignSelf: 'center', mx: {xs: 0, md: 6}}}>
        <img src={iconSrc} height={168} alt="" />
      </Box>
      <Box
        sx={{
          pt: 2,
          color: 'mono.main',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        id="feedback-card-content">
        <Typography variant="h4" gutterBottom>
          {feedbackTitle}
        </Typography>
        <Typography paragraph gutterBottom>
          {description}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/feedback"
          sx={{ml: 'auto', color: 'mono.main'}}>
          {feedbackButtonTitle}
        </Button>
      </Box>
    </Card>
  );
};

export default HomeFeedbackCard;
