import React from 'react';

import Select from '@mui/material/Select';
import {Typography} from '@mui/material';

import {CURRENT_YEAR, CURRENT_MONTH, MONTHS} from '../../Util/constants';
import {range} from '../../Util/helpers';

const YoungPersonDateInputPage = ({
  title,
  subtitle,
  month,
  setMonth,
  year,
  setYear,
  minYear,
  maxYear,
  allowFutureOnly = false,
}) => {
  // If allowFutureOnly is active:
  //    if the current year is selected, only show current & future months
  const validMonths =
    allowFutureOnly && parseInt(year, 10) === CURRENT_YEAR
      ? // (Note: MONTHS is 1-indexed, CURRENT_MONTH is 0-indexed)
        Object.entries(MONTHS).filter(([key]) => key >= CURRENT_MONTH + 1)
      : Object.entries(MONTHS);
  return (
    <>
      <Typography variant="h2" gutterBottom>
        {title}
      </Typography>
      <Typography paragraph gutterBottom sx={{whiteSpace: 'pre-line'}}>
        {subtitle}
      </Typography>
      <div style={{display: 'flex', justifyContent: 'center', margin: 15}}>
        <Select
          native
          name="month"
          fullWidth
          sx={{mr: 2}}
          value={month ?? ''}
          displayEmpty
          autoFocus
          onChange={(event) => setMonth(event.target.value)}>
          <option value={''} disabled>
            Choose month
          </option>
          {validMonths.map(([key, value]) => (
            <option value={key} key={`month-${value}`}>
              {value}
            </option>
          ))}
        </Select>
        <Select
          native
          name="year"
          fullWidth
          value={year}
          displayEmpty
          onChange={(event) => setYear(event.target.value)}>
          <option value={''} disabled>
            Choose year
          </option>
          {range(minYear, maxYear)
            .reverse()
            .map((value) => (
              <option value={value} key={`year-${value}`}>
                {value}
              </option>
            ))}
        </Select>
      </div>
    </>
  );
};

export default YoungPersonDateInputPage;
