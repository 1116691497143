import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {Alert, Box, FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';

import {useOnboarding} from '../OnboardingProvider';

const LocalAuthorityPage = ({
  title,
  subtitle,
  localAuthority,
  livesInScotland,
  setLivesInScotland,
  setLocalAuthority,
  livesInScotlandQuestion,
  livesInScotlandCopy,
}) => {
  const {onboardingState} = useOnboarding();

  const selectedLocalAuthority =
    onboardingState.localAuthorities &&
    onboardingState.localAuthorities.find(
      (localAuthorityOption) => localAuthorityOption.name === localAuthority
    );

  return (
    <>
      <Typography variant="h2" component="h1" gutterBottom>
        {livesInScotlandQuestion ?? title}
      </Typography>
      {livesInScotlandQuestion ? (
        <Box>
          <RadioGroup
            sx={{mb: 2}}
            aria-labelledby="region-radio-buttons-group"
            name="region-radio-buttons-group"
            value={livesInScotland}
            onChange={(event) => {
              const selectedLivesInScotland = event.target.value === 'true';
              setLivesInScotland(selectedLivesInScotland);
              if (!selectedLivesInScotland) {
                setLocalAuthority(null);
              }
            }}>
            <FormControlLabel value={true} control={<Radio />} label={'Yes'} />
            <FormControlLabel value={false} control={<Radio />} label={'No'} />
          </RadioGroup>
        </Box>
      ) : null}
      {livesInScotland === true ? (
        <>
          <Typography variant="h3" gutterBottom>
            {title}
          </Typography>
          <Typography paragraph>{subtitle}</Typography>
          {onboardingState.localAuthorities?.length > 0 ? (
            <Autocomplete
              id="local-authority"
              autoHighlight
              value={selectedLocalAuthority || null}
              options={onboardingState.localAuthorities}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option) => option.name === localAuthority}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Type your council area here"
                  autoFocus
                  inputProps={{
                    ...params.inputProps,
                    'data-testid': 'local-authority',
                  }}
                />
              )}
              onChange={(_event, value) => {
                if (value) {
                  setLocalAuthority(value.name);
                } else {
                  setLocalAuthority(undefined);
                }
              }}
            />
          ) : null}
        </>
      ) : livesInScotland === false ? (
        <Alert severity="info">
          <Typography paragraph>{livesInScotlandCopy}</Typography>
        </Alert>
      ) : null}
    </>
  );
};

export default LocalAuthorityPage;
