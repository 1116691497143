import React, {useEffect, useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Box, Button, Card, CardContent, Container, Typography} from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import {useAuth} from '../Auth/AuthProvider';
import {useYoungPersonTransition} from './YoungPersonTransitionProvider';
import useDialogQuery from '../Util/useDialogQuery';
import YoungPersonTransitionProgressIndicator from './YoungPersonTransitionProgressIndicator';
import {
  TOPIC_IMPORTANCE_RATINGS,
  sectionIcons,
  FEEDBACK_STRINGS,
  DIALOG_URL_PARAMS,
} from '../Util/constants';
import HomeScreenCard from '../Parent/Home/HomeScreenCard';
import HomeScreenTour from '../Util/HomeScreenTour';
import HomeTopicsCard from './HomeTopicsCard';
import YPHomeScreenCardWide from './YPHomeScreenCardWide';
import HomeFeedbackCard from '../HomeFeedbackCard';
import {useRedirectAfterAuth} from '../Util/StoreRedirectUrlForAuth';

const {feedbackIcon, ratingsIcon, ratingsIconDisabled} = sectionIcons;
const {feedbackButtonTitle} = FEEDBACK_STRINGS.youngPerson;

const TOUR_JOYRIDE_STEPS = [
  {
    target: 'body',
    placement: 'center',
    disableBeacon: true,
    title: 'Welcome to Compass!',
    content:
      'Compass helps you work out what matters to you and helps you tell other people about this. This short guided tour will help you know how to use Compass.',
  },
  {
    target: '#home-button',
    disableBeacon: true,
    title: 'Home button',
    content: 'Press the home button whenever you want to return to the home screen.',
  },
  {
    target: '#menu-button',
    title: 'Menu',
    content:
      'You can access all the different areas of Compass here and change your settings when you want.',
  },
  {
    target: '#stage-details',
    title: 'Your progress',
    content:
      'The progress bar will change as you move towards the date you told Compass your big change is going to happen.',
  },
  {
    target: '#critical-topics-card',
    title: 'Important topics',
    content:
      ' Use this button to explore important topics based on the big change you are going through.',
  },
  {
    target: '#transition-topics-card',
    title: 'Transition topics',
    content:
      'Use this button to explore all the things important or not so important to you and rate them.',
  },
  {
    target: '.topics-grid',
    title: 'Your ratings',
    content:
      'When you have rated some topics, you can go here to find out more about them and share them with other people if you want.',
  },
  {
    target: '#feedback-card-content',
    title: 'Giving feedback',
    content:
      'Use this button to tell people who support you, like teachers and other professionals, how things are going and what could be better about their help.',
  },
  {
    target: '#help-button',
    title: 'Help button',
    content: 'You can view this tutorial at any time using the help button.',
  },
];

const YPHomeScreenCardHorizontal = ({id, onClick, disabled = false, icon, description}) => {
  return (
    <Card
      sx={{
        borderRadius: 5,
        mb: 2,
        cursor: disabled ? 'not-allowed' : 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 2,
        padding: 2,
      }}
      onClick={onClick}
      id={id}
      disabled={disabled}>
      {icon}
      <div className="header-4">{description}</div>
      <ArrowForwardIcon />
    </Card>
  );
};

const YoungPersonHome = () => {
  const {authState, hasRatedTopics, updateUser} = useAuth();
  const {openDialog} = useDialogQuery();
  const theme = useTheme();
  const navigate = useNavigate();

  // If there's a stored URL when we mount, navigate there
  const {handleAuthenticatedAppBoot} = useRedirectAfterAuth();
  useEffect(handleAuthenticatedAppBoot, []);

  const {percentage_to_transition} = authState.user.profile;
  const {secondary, notSure, mono} = theme.palette;
  const criticalBg = secondary.dark;
  const transitionTypeTitle = authState.user.profile.transition_type.name;
  const {unratedCriticalTopics} = useYoungPersonTransition();
  const hasUnratedCriticalTopics = unratedCriticalTopics.length > 0;

  const [showTour, setShowTour] = useState(authState.user.should_show_intro_tour);
  const onCompleteTour = async () => {
    await updateUser({should_show_intro_tour: false});
  };

  // TODO - should this also trigger when the user has rated all topics
  const shouldShowChangeTransitionType = percentage_to_transition >= 100;

  return (
    <>
      <HomeScreenTour
        run={showTour}
        setRun={setShowTour}
        steps={TOUR_JOYRIDE_STEPS}
        onComplete={onCompleteTour}
        hideProgress
      />
      <Box id="home-banner" sx={{pt: 4}}>
        <Container maxWidth="md">
          <div className="name-row">
            <Typography variant="h1" className="white">
              {`Hello ${authState.user.name}!`}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowTour(true)}
              id="help-button">
              Help
            </Button>
          </div>
          <YoungPersonTransitionProgressIndicator />
        </Container>
      </Box>

      <Container maxWidth="md">
        {shouldShowChangeTransitionType && (
          <Card sx={{mb: 5}}>
            <CardContent sx={{p: 3}}>
              {percentage_to_transition >= 100 && (
                <Typography variant="h3" gutterBottom>
                  You have reached the date of your Big Change
                </Typography>
              )}
              <Typography paragraph>
                It might be helpful to update your Big Change type, so you can explore topics for
                your next Big Change.
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="secondary"
                  component={Link}
                  to="/update-big-change">
                  Update your Big Change
                </Button>
              </Box>
            </CardContent>
          </Card>
        )}

        <Typography variant="h3" gutterBottom>
          Here you can...
        </Typography>

        {hasUnratedCriticalTopics ? (
          <YPHomeScreenCardWide
            id="critical-topics-card"
            onClick={() => navigate('critical-topics')}
            backgroundColor={criticalBg}
            icon={<StarsIcon sx={{height: 80, fontSize: 80, color: 'white'}} />}
            description={`Look at topics that are important for ${transitionTypeTitle.toLowerCase()}`}
          />
        ) : (
          <HomeTopicsCard />
        )}

        {!hasRatedTopics && (
          <Typography variant="h3" gutterBottom>
            Go to 'helpful information and decide what matters to you' to unlock...
          </Typography>
        )}
        <div className={`topics-grid`}>
          <YPHomeScreenCardHorizontal
            id="topics-that-matter-button"
            onClick={() => openDialog(DIALOG_URL_PARAMS.BACKPACK)}
            disabled={!hasRatedTopics}
            icon={
              <img
                src={
                  hasRatedTopics
                    ? TOPIC_IMPORTANCE_RATINGS[1].squareIcon
                    : TOPIC_IMPORTANCE_RATINGS[1].monoSquareIcon
                }
                width={40}
                alt=""
              />
            }
            description="See topics that matter to you"
          />
          <YPHomeScreenCardHorizontal
            id="topics-unsure-button"
            onClick={() => openDialog(DIALOG_URL_PARAMS.UNSURE)}
            disabled={!hasRatedTopics}
            icon={
              <img
                src={
                  hasRatedTopics
                    ? TOPIC_IMPORTANCE_RATINGS[2].squareIcon
                    : TOPIC_IMPORTANCE_RATINGS[2].monoSquareIcon
                }
                width={40}
                alt=""
              />
            }
            description="See topics that you weren't sure about"
          />
          <HomeScreenCard
            id="summary"
            headerBackgroundColor={hasRatedTopics ? notSure.base : mono.pale}
            headerIconSrc={hasRatedTopics ? ratingsIcon : ratingsIconDisabled}
            actionRow={<div className="header-4">See all your ratings</div>}
            onClick={() => openDialog(DIALOG_URL_PARAMS.SUMMARY)}
            disabled={!hasRatedTopics}
          />
        </div>

        <HomeFeedbackCard
          iconSrc={feedbackIcon}
          feedbackTitle={`How is ${transitionTypeTitle?.toLowerCase()} going?`}
          description="People who support you would like to know what you think is going well and what needs to get better."
          feedbackButtonTitle={feedbackButtonTitle}
        />
      </Container>
    </>
  );
};

export default YoungPersonHome;
