import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Link, Paper, Typography} from '@mui/material';

import {
  TOPIC_IMPORTANCE_RATINGS,
  TOPIC_CHALLENGE_RATINGS,
  NUM_TRANSITION_TOPIC_LINKS,
} from '../Util/constants';
import {YOUNG_PERSON_CATEGORY_TOPIC_ICONS} from './transitionIcons';
import {useYoungPersonTransition} from './YoungPersonTransitionProvider';
import linkGraphic from '../img/yp-transition-topic-link-graphic.svg';

const RatedTopicDetails = ({topic, beforeUpdateButtonPress}) => {
  const navigate = useNavigate();
  const {categories} = useYoungPersonTransition();

  const subtopics = topic?.subtopics ? topic?.subtopics?.split('~') : [];
  const category = categories?.find((category) => category.id === topic.transition_category_id);
  let topicLinks = [];
  if (topic) {
    for (let i = 0; i < NUM_TRANSITION_TOPIC_LINKS; i++) {
      const linkNumber = i + 1;
      const link = topic[`link_${linkNumber}`];
      const title = topic[`link_${linkNumber}_title`];
      if (link) {
        topicLinks.push({link, title});
      }
    }
  }

  return (
    <>
      <Paper className="topic-card" sx={{borderRadius: 5, mt: 6}}>
        <img
          className="view-topic-icon"
          src={YOUNG_PERSON_CATEGORY_TOPIC_ICONS[topic.key]}
          width={60}
          height={60}
          alt=""
        />
        <Typography variant="h3" align="center" gutterBottom>
          {category.name}
        </Typography>
        <Typography variant="h2" align="center" sx={{mb: 3}}>
          {topic.name}
        </Typography>
        <div className="view-topic-rating-row">
          <img
            src={TOPIC_IMPORTANCE_RATINGS[topic.rating.importance_rating].icon}
            width={25}
            height={25}
            alt=""
          />
          <span>{TOPIC_IMPORTANCE_RATINGS[topic.rating.importance_rating].summary}</span>
        </div>
        <div className="view-topic-rating-row">
          <img
            src={TOPIC_CHALLENGE_RATINGS[topic.rating.challenge_rating].icon}
            width={25}
            height={25}
            alt=""
          />
          <span>{`This is a topic you ${
            TOPIC_CHALLENGE_RATINGS[topic.rating.challenge_rating].summary
          }`}</span>
        </div>

        <div className="right-aligned-button">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              beforeUpdateButtonPress && beforeUpdateButtonPress();
              navigate(`/categories/${category.id}/topics/${topic.id}/rate`);
            }}>
            Update my rating
          </Button>
        </div>
      </Paper>

      <Paper className="topic-card" sx={{borderRadius: 5}}>
        <Typography>{topic.description}</Typography>
        <ul>
          {subtopics.map((subtopic, index) => (
            <li key={`subtopic-${index}`}>{subtopic}</li>
          ))}
        </ul>
      </Paper>

      {topicLinks?.length > 0 && (
        <Paper className="topic-card" sx={{borderRadius: 5}}>
          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
            <Typography variant="h3">Helpful Links</Typography>
            <img src={linkGraphic} width={80} height={80} alt="" />
          </Box>
          <ul>
            {topicLinks?.map(({link, title}) => (
              <li key={link}>
                <Link href={link} target="_blank" rel="noreferrer">
                  {title ? title : link}
                </Link>
              </li>
            ))}
          </ul>
        </Paper>
      )}
    </>
  );
};

export default RatedTopicDetails;
