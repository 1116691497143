import React, {useState} from 'react';
import {useNavigate, Link as RouterLink} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Button, Container, Link, Paper, Typography} from '@mui/material';

import {useOnboarding} from '../Onboarding/OnboardingProvider';
import {useAuth} from '../Auth/AuthProvider';
import PageHeader from '../Util/PageHeader';
import {FEEDBACK_STRINGS} from '../Util/constants';
import feedbackIcon from '../img/EvaluationLight.svg';
import AdditionalSupportNeedsPage from '../Onboarding/OnboardingPages/AdditionalSupportNeedsPage';
import AboutYouIdentifiesAsDisabled from './AboutYouIndentifiesAsDisabled';
import AboutYouChallengeLevel from './AboutYouChallengeLevel';
import ypFeedbackAboutYouIllustration from '../img/yp-feedback-about-you.svg';

const YoungPersonEvaluationAbout = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {onboardingState} = useOnboarding();
  const {authState, updateYoungPersonProfile} = useAuth();

  const [currentFieldIndex, setCurrentFieldIndex] = useState(0);
  const [aboutYouFields, setAboutYouFields] = useState([
    'why',
    'identifiesAsDisabled',
    'challengeLevel',
    'additionalSupportNeedCategories',
    'thankYou',
  ]);

  const [identifiesAsDisabled, setIdentifiesAsDisabled] = useState('');
  const [challengeLevel, setChallengeLevel] = useState('');
  const [additionalSupportNeedCategories, setAdditionalSupportNeedCategories] = useState([]);
  const [customAdditionalSupportNeedCategories, setCustomAdditionalSupportNeedCategories] =
    useState([]);

  const isNonFinalField = currentFieldIndex < aboutYouFields.length - 1;
  const isLastField = currentFieldIndex === aboutYouFields.length - 1;

  const handlePrevButtonPress = () => {
    setCurrentFieldIndex(currentFieldIndex - 1);
  };

  const handleContinue = async () => {
    if (isNonFinalField) {
      setCurrentFieldIndex(currentFieldIndex + 1);
    } else {
      await updateYoungPersonProfile({
        id: authState.user?.profile?.id,
        identifies_as_disabled: identifiesAsDisabled,
        challenge_level: challengeLevel,
        additional_support_need_categories: additionalSupportNeedCategories,
        custom_additional_support_need_categories: customAdditionalSupportNeedCategories,
      });
      navigate('/feedback/about');
    }
  };

  const shouldShowField = (field) => aboutYouFields.indexOf(field) === currentFieldIndex;
  const currentFieldKey = aboutYouFields[currentFieldIndex];

  const handleSkip = () => {
    if (currentFieldKey === 'identifiesAsDisabled') {
      setIdentifiesAsDisabled('');
    } else if (currentFieldKey === 'challengeLevel') {
      setChallengeLevel('');
    } else if (currentFieldKey === 'additionalSupportNeedCategories') {
      setAdditionalSupportNeedCategories([]);
      setCustomAdditionalSupportNeedCategories([]);
    }
    setCurrentFieldIndex(currentFieldIndex + 1);
  };

  return (
    <>
      <PageHeader
        color={theme.palette.pink}
        name={FEEDBACK_STRINGS.youngPerson.feedbackTitle}
        icon={feedbackIcon}
        backTo="/feedback"
      />
      <div className="center-content">
        <Container maxWidth="md" sx={{py: 6}}>
          {shouldShowField('why') && (
            <>
              <Typography variant="h2" gutterBottom>
                Why we ask 'about you' questions
              </Typography>
              <div className="centre-aligned">
                <img src={ypFeedbackAboutYouIllustration} className="screen-illustration" />
              </div>
              <Typography paragraph>
                Sometimes people support you when you are making choices. They do not always know if
                they are getting it right for you.
              </Typography>
              <Typography paragraph>
                Using compass to tell Teachers, Social Workers and others about your experience can
                help them know what is working and what could be better. Telling Compass about how
                you feel will make things better for all young people in Scotland.
              </Typography>
              <Typography paragraph>
                Compass does not ever tell anyone who gave an answer. You can read the{' '}
                <Link component={RouterLink} to="/privacy" target="_blank" rel="noreferrer">
                  Privacy Notice
                </Link>{' '}
                if you want to know how your answers are used.
              </Typography>
              <Typography paragraph>
                You do not have to answer questions you do not want to.
              </Typography>
              <Typography paragraph>
                Be honest. Say if things are bad or good or you do not know.
              </Typography>
            </>
          )}

          {shouldShowField('identifiesAsDisabled') && (
            <>
              <Typography variant="h2" gutterBottom>
                About You
              </Typography>
              <Paper sx={{borderRadius: 5, p: 4, mb: 2}}>
                <AboutYouIdentifiesAsDisabled
                  identifiesAsDisabled={identifiesAsDisabled}
                  onChange={(newIdentifiesAsDisabledValue) => {
                    setIdentifiesAsDisabled(newIdentifiesAsDisabledValue);
                    // remove questions questions 2 & 3 from flow for users who answer 'no'
                    if (newIdentifiesAsDisabledValue === 'No') {
                      setAboutYouFields(['why', 'identifiesAsDisabled', 'thankYou']);
                    } else {
                      setAboutYouFields([
                        'why',
                        'identifiesAsDisabled',
                        'challengeLevel',
                        'additionalSupportNeedCategories',
                        'thankYou',
                      ]);
                    }
                  }}
                />
              </Paper>
            </>
          )}

          {shouldShowField('challengeLevel') && (
            <>
              <Typography variant="h2" gutterBottom>
                About You
              </Typography>
              <Paper sx={{borderRadius: 5, p: 4, mb: 2}}>
                <AboutYouChallengeLevel
                  challengeLevel={challengeLevel}
                  onChange={(newChallengeLevel) => setChallengeLevel(newChallengeLevel)}
                />
              </Paper>
            </>
          )}

          {shouldShowField('additionalSupportNeedCategories') && (
            <>
              <Typography variant="h2" gutterBottom>
                About You
              </Typography>
              <Paper sx={{borderRadius: 5, p: 4, mb: 2}}>
                {onboardingState.youngPersonAdditionalSupportNeedCategories ? (
                  <AdditionalSupportNeedsPage
                    heading="What is the nature of your additional support need or disability?"
                    caption="Tick all boxes that apply to you."
                    additionalSupportNeeds={
                      onboardingState.youngPersonAdditionalSupportNeedCategories
                    }
                    selectedAdditionalSupportNeeds={additionalSupportNeedCategories}
                    toggleAdditionalSupportNeed={(name, isChecked) => {
                      if (isChecked) {
                        setAdditionalSupportNeedCategories([
                          ...additionalSupportNeedCategories,
                          name,
                        ]);
                      } else {
                        const newAdditionalSupportNeeds = additionalSupportNeedCategories.filter(
                          (item) => item !== name
                        );
                        setAdditionalSupportNeedCategories(newAdditionalSupportNeeds);
                      }
                    }}
                    customAdditionalSupportNeeds={customAdditionalSupportNeedCategories}
                    setCustomAdditionalSupportNeeds={(newCustomAdditionalSupportNeeds) =>
                      setCustomAdditionalSupportNeedCategories(newCustomAdditionalSupportNeeds)
                    }
                    otherLabel="Other (including any illness like cancer or HIV). Please specify"
                  />
                ) : (
                  <div>Loading...</div>
                )}
              </Paper>
            </>
          )}

          {shouldShowField('thankYou') && (
            <div>
              <Typography variant="h2" gutterBottom>
                Thank you for answering these questions.
              </Typography>
              <Typography paragraph>
                You can change your answers in the 'My Account' screen.
              </Typography>
            </div>
          )}

          <div className={currentFieldIndex > 0 ? 'register-buttons' : 'form-button'}>
            {currentFieldIndex > 0 && (
              <Button variant="outlined" color="secondary" onClick={handlePrevButtonPress}>
                Previous
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleContinue}
              autoFocus={currentFieldIndex === 0}>
              {currentFieldIndex === 0 || isLastField ? 'Continue' : 'Next'}
            </Button>
          </div>
          {!shouldShowField('why') && !shouldShowField('identifiesAsDisabled') && !isLastField && (
            <Button variant="text" style={{float: 'right'}} onClick={handleSkip}>
              Skip Question
            </Button>
          )}
        </Container>
      </div>
    </>
  );
};

export default YoungPersonEvaluationAbout;
