import React from 'react';
import {useTheme} from '@mui/material/styles';
import {Box, Container, Typography} from '@mui/material';

import FeedbackHomeScreen from '../Evaluation/FeedbackHomeScreen';
import PageHeader from '../Util/PageHeader';
import {FEEDBACK_STRINGS} from '../Util/constants';
import feedbackIcon from '../img/feedback.png';
import ypFeedbackIllustration from '../img/yp-feedback.svg';

const YoungPersonFeedbackScreen = () => {
  const theme = useTheme();
  const {feedbackTitle, feedbackButtonTitle, feedbackHistoryTitle} = FEEDBACK_STRINGS.youngPerson;

  return (
    <FeedbackHomeScreen
      pageHeader={
        <>
          <PageHeader color={theme.palette.pink} name={feedbackTitle} icon={feedbackIcon} />
          <Container maxWidth="md" sx={{py: 4}}>
            <Box
              component="img"
              src={ypFeedbackIllustration}
              sx={{display: 'block', mx: 'auto', width: {sm: 200, md: 300}}}
            />
            <Box sx={{mt: 3}}>
              <Typography paragraph>
                Sometimes people support you when you are making choices. They do not always know if
                they are getting it right for you.
              </Typography>
              <Typography paragraph>
                You can tell them how you feel about their support by using the '
                {feedbackButtonTitle}' button anytime you want.
              </Typography>
              <Typography paragraph>
                You and the people supporting you can see the answers you have given before, using
                the '{feedbackHistoryTitle}' button.
              </Typography>
            </Box>
          </Container>
        </>
      }
      feedbackColor={theme.palette.pink}
      feedbackButtonTitle={feedbackButtonTitle}
      feedbackHistoryTitle={feedbackHistoryTitle}
    />
  );
};

export default YoungPersonFeedbackScreen;
