import React from 'react';

const NavButton = ({className = '', onClick, title, icon, backgroundColor, iconSize}) => {
  const size = iconSize === 'small' ? 50 : 60;
  return (
    <button
      className={`button-reset nav-button ${className}`}
      style={{
        backgroundColor,
      }}
      onClick={onClick}>
      <div className="header-5">{title}</div>
      {icon && <img src={icon} height={size} width={size} />}
    </button>
  );
};

export default NavButton;
