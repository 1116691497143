import React, {createContext, useContext, useReducer, useEffect, useState} from 'react';

import {useLocation} from 'react-router-dom';
import {useAuth} from '../Auth/AuthProvider';

export const EvaluationContext = createContext();

const CHECK_DUE_URL = '/api/evaluation/check-due';
const START_NEW_EVALUATION_URL = '/api/evaluation';
const SAVE_RESPONSE_URL = '/api/evaluation/response';
const SAVE_COMMENTS_URL = '/api/evaluation/comments';
const FETCH_QUESTIONS_URL = '/api/evaluation/questions';
const FETCH_HISTORY_URL = '/api/evaluation/history';
const FETCH_REMAINING_SNOOZE_COUNT_URL = '/api/evaluation/remaining-snooze-count';
const INCREMENT_SNOOZE_COUNT_URL = '/api/evaluation/snooze';

const initialState = {
  questionsByThemeName: undefined,
  evaluations: undefined,
  responsesByThemeName: undefined,
  isLoading: false,
  currentThemeIndex: 0,
  remainingSnoozeCount: 0,
};

const evaluationReducer = (evaluationState, action) => {
  switch (action.type) {
    case 'START_REQUEST': {
      return Object.assign({}, evaluationState, {
        isLoading: true,
      });
    }
    case 'STOP_REQUEST': {
      return Object.assign({}, evaluationState, {
        isLoading: false,
      });
    }
    case 'SET_QUESTIONS': {
      // If the backend returns {}, there is no active evaluation
      // So detect and reset questions to undefined, so the user can start a new one
      let questionsByThemeName =
        Object.values(action.payload).length !== 0 ? Object.values(action.payload) : undefined;
      return Object.assign({}, evaluationState, {
        questionsByThemeName,
        currentThemeIndex: 0,
        isLoading: false,
      });
    }
    case 'SET_EVALUATIONS_AND_RESPONSES': {
      let evaluations = action.payload;
      let responsesByTheme = {};
      evaluations.forEach((evaluation) => {
        evaluation.responses.forEach((response) => {
          const evaluationId = evaluation.id;
          const themeName = response.evaluation_question.theme.name;
          if (!(themeName in responsesByTheme)) {
            responsesByTheme[themeName] = {};
          }
          if (!(evaluationId in responsesByTheme[themeName])) {
            responsesByTheme[themeName][evaluationId] = [];
          }
          responsesByTheme[themeName][evaluationId].push(response);
        });
      });
      return Object.assign({}, evaluationState, {
        evaluations: evaluations,
        responsesByThemeName: responsesByTheme,
        isLoading: false,
      });
    }
    case 'INCREMENT_CURRENT_THEME_INDEX': {
      return Object.assign({}, evaluationState, {
        currentThemeIndex: evaluationState.currentThemeIndex + 1,
        isLoading: false,
      });
    }
    case 'SET_REMAINING_SNOOZE_COUNT': {
      return Object.assign({}, evaluationState, {
        remainingSnoozeCount: action.payload,
      });
    }
  }
};

function EvaluationProvider({children, EvaluationPromptDialog}) {
  const [evaluationState, dispatch] = useReducer(evaluationReducer, initialState);
  const location = useLocation();
  const {axios, hasFetchedUser, isParent, isYoungPerson} = useAuth();
  const [showEvaluationPrompt, setShowEvaluationPrompt] = useState(false);

  useEffect(() => {
    const checkEvaluationDue = async () => {
      dispatch({type: 'START_REQUEST'});
      const checkDueResponse = await axios.get(CHECK_DUE_URL);
      dispatch({type: 'STOP_REQUEST'});

      if (checkDueResponse.data) {
        // only parents have a pre-defined snooze limit
        // so only check remaining snoozes for parents
        if (isParent) {
          await fetchRemainingSnoozeCount();
        }
        // fetch history to check if user has any existing evaluations
        // we use this to determine if they should see 'about you' questions
        if (isYoungPerson) {
          await fetchHistory();
        }
        setShowEvaluationPrompt(true);
      }
    };
    // Only check if we need to prompt once we've fetched the user and
    // If we're not already on the evaluation screen
    if (
      hasFetchedUser &&
      !['/feedback/new', '/feedback/about', '/feedback/about-you'].includes(location.pathname)
    ) {
      checkEvaluationDue();
    }
  }, [hasFetchedUser]);

  const createNewEvaluation = async (youngPersonId) => {
    dispatch({type: 'START_REQUEST'});
    await axios.post(START_NEW_EVALUATION_URL, {young_person_id: youngPersonId});
    dispatch({type: 'STOP_REQUEST'});
  };

  const fetchQuestions = async () => {
    dispatch({type: 'START_REQUEST'});
    const questions = await axios.get(FETCH_QUESTIONS_URL);
    dispatch({type: 'SET_QUESTIONS', payload: questions.data});
  };

  const checkHasActiveEvaluation = async () => {
    dispatch({type: 'START_REQUEST'});
    const questions = await axios.get(FETCH_QUESTIONS_URL);
    dispatch({type: 'STOP_REQUEST'});
    return (
      Object.values(questions.data).length !== undefined && Object.values(questions.data).length > 0
    );
  };

  const fetchHistory = async () => {
    try {
      dispatch({type: 'START_REQUEST'});
      const historyResponse = await axios.get(FETCH_HISTORY_URL);
      const evaluations = historyResponse.data;
      dispatch({type: 'SET_EVALUATIONS_AND_RESPONSES', payload: evaluations});
    } catch {
      alert('There was a problem retrieving your evaluation history.');
      dispatch({type: 'STOP_REQUEST'});
    }
  };

  const saveResponse = async (responses) => {
    try {
      dispatch({type: 'START_REQUEST'});
      await axios.post(SAVE_RESPONSE_URL, {responses});
      dispatch({type: 'INCREMENT_CURRENT_THEME_INDEX'});
    } catch {
      alert('There was a problem saving your results. Please refresh the page and try again.');
      dispatch({type: 'STOP_REQUEST'});
    }
  };

  const saveComments = async (otherComments) => {
    dispatch({type: 'START_REQUEST'});
    const response = await axios.post(SAVE_COMMENTS_URL, {otherComments});
    dispatch({type: 'STOP_REQUEST'});
    return response;
  };

  const fetchRemainingSnoozeCount = async () => {
    const snoozeCountResponse = await axios.get(FETCH_REMAINING_SNOOZE_COUNT_URL);
    dispatch({type: 'SET_REMAINING_SNOOZE_COUNT', payload: snoozeCountResponse.data});
  };

  const incrementSnoozeCount = async () => {
    try {
      const incrementResponse = await axios.put(INCREMENT_SNOOZE_COUNT_URL);
      dispatch({type: 'SET_REMAINING_SNOOZE_COUNT', payload: incrementResponse.data});
    } catch {
      alert('There was a problem snoozing your evaluation Please refresh the page and try again.');
    }
  };
  const isLoading = evaluationState.isLoading;
  const value = {
    evaluationState,
    fetchQuestions,
    fetchHistory,
    saveResponse,
    saveComments,
    incrementSnoozeCount,
    dispatch,
    createNewEvaluation,
    isLoading,
    checkHasActiveEvaluation,
  };
  return (
    <EvaluationContext.Provider value={value}>
      <>
        {children}
        <EvaluationPromptDialog
          isVisible={showEvaluationPrompt}
          closeDialog={() => setShowEvaluationPrompt(false)}
        />
      </>
    </EvaluationContext.Provider>
  );
}

const useEvaluation = () => useContext(EvaluationContext);

export {EvaluationProvider, useEvaluation, evaluationReducer};
