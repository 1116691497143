import React from 'react';
import {Box, Button, ButtonBase, Container, Typography} from '@mui/material';

import {compassArrowIcons, YOUNG_PERSON_CATEGORY_ICONS} from '../Util/constants';
import allTopicsRatedIcon from '../img/complete-tick-primary-filled.svg';
import hasUnsureTopicsIcon from '../img/not-sure-ratings.svg';
import CategoryState from './CategoryState';

const YoungPersonCategoryTile = ({category, onSelectCategory, viewMode}) => {
  const unratedTopics = category?.topics.filter(
    (topic) => !topic.rating || topic.rating?.importance_rating === 2
  );
  const allTopicsRated = unratedTopics.length === 0;
  const unsureTopics = category?.topics.filter((topic) => topic.rating?.importance_rating === 2);
  const hasUnsureTopics = unsureTopics.length > 0;

  const categoryState = allTopicsRated ? (
    <CategoryState icon={allTopicsRatedIcon} text="All topics rated" />
  ) : (
    hasUnsureTopics && <CategoryState icon={hasUnsureTopicsIcon} text="Not sure ratings" />
  );
  return viewMode === 'minimal' ? (
    <div className="minimal-category-view">
      <Container maxWidth="md" sx={{color: 'mono.main'}}>
        {categoryState}
        <img src={YOUNG_PERSON_CATEGORY_ICONS[category.key]} width={150} height={150} />
        <Typography variant="h3" gutterBottom>
          {category.name}
        </Typography>
        <Typography>{category.description}</Typography>
        <div className="right-aligned-button">
          <Button variant="contained" color="secondary" onClick={() => onSelectCategory(category)}>
            See Category
          </Button>
        </div>
      </Container>
    </div>
  ) : (
    <ButtonBase
      className={`button-reset category`}
      onClick={() => onSelectCategory(category)}
      sx={{
        color: 'mono.main',
        borderRadius: 5,
        mb: 2,
        p: {xs: 2, md: 4},
        alignItems: 'flex-start',
      }}>
      {categoryState}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Typography variant="h3" gutterBottom>
          {category.name}
        </Typography>
        <img src={YOUNG_PERSON_CATEGORY_ICONS[category.key]} width={150} height={150} />
      </Box>
      {viewMode === 'medium' && (
        <>
          <Typography variant="body2">{category.description}</Typography>
          <img
            src={compassArrowIcons.rightFacingArrowIcon.secondary}
            style={{alignSelf: 'flex-end'}}
          />
        </>
      )}
    </ButtonBase>
  );
};

export default YoungPersonCategoryTile;
