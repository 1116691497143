import React, {createContext, useContext, useEffect, useState} from 'react';

import {useAuth} from '../Auth/AuthProvider';

export const YoungPersonTransitionContext = createContext();

const YOUNG_PERSON_TRANSITION_CATEGORIES_URL = '/api/transition/categories';

function YoungPersonTransitionProvider({children}) {
  const {authState, axios, fetchUser} = useAuth();
  const [categories, setCategories] = useState(undefined);

  const criticalTopics = authState.user.profile.transition_type.critical_topics;
  /*
  make unratedCriticalTopics have the shape:
    [
      {
        id: integer
        transition_topics: {
          rating: {}
        }
        transition_topic_id: integer
        transition_type_id: integer
      }
    ]
  */
  const unratedCriticalTopics =
    criticalTopics
      ?.filter(
        (criticalTopic) =>
          !authState.user.profile.current_topic_ratings.find(
            (rating) =>
              rating.young_person_transition_topic_id === criticalTopic.transition_topic.id
          )
      )
      .map((topicPivot) => ({
        ...topicPivot,
        ...topicPivot.transition_topic,
        rating: authState.user.profile.current_topic_ratings.find(
          (rating) => rating.young_person_transition_topic_id === topicPivot.transition_topic_id
        ),
      })) ?? [];

  const categoriesWithTopicRatings =
    categories?.map((category) => ({
      ...category,
      topics: category.topics.map((topic) => ({
        ...topic,
        rating: authState.user.profile.current_topic_ratings.find(
          (rating) => rating.young_person_transition_topic_id === topic.id
        ),
      })),
    })) ?? [];

  const getNextUnratedTopicWithCurrentCategory = (categoryId, index = 0) => {
    // index handles situation where we call this after saving but before the provider state has updated
    // i.e index=1 means skip the *current* next, as it's actually just been rated
    return (
      unratedCriticalTopics[index] ??
      // Find the next unrated topic in the given categoryId
      categoriesWithTopicRatings
        .find((category) => category.id === parseInt(categoryId))
        ?.topics.filter((topic) => !topic.rating)[index]
    );
  };

  // We can know if the user just rated the last critical topic if all of their topic ratings correspond to critical topics
  const criticalTopicIDs = criticalTopics.map((topic) => topic.transition_topic_id);
  const ratedTopicIDs = authState.user.profile.current_topic_ratings.map(
    (rating) => rating.young_person_transition_topic_id
  );
  const userRatedAllAndOnlyCriticalTopics =
    criticalTopicIDs
      .filter((criticalID) => !ratedTopicIDs.includes(criticalID))
      .concat(ratedTopicIDs.filter((ratedId) => !criticalTopicIDs.includes(ratedId))).length === 0;

  const allTopics = categoriesWithTopicRatings.map((category) => category.topics).flat();

  const backpackTopics = allTopics?.filter((topic) => topic.rating?.importance_rating === 1) ?? [];
  const unsureTopics = allTopics?.filter((topic) => topic.rating?.importance_rating === 2) ?? [];
  const tentTopics = allTopics?.filter((topic) => topic.rating?.importance_rating === 3) ?? [];

  const ratedBackpackTopicsByChallenge = {
    1: [],
    2: [],
    3: [],
  };

  const ratedUnsureTopicsByChallenge = {
    1: [],
    2: [],
    3: [],
  };

  const ratedTentTopicsByChallenge = {
    1: [],
    2: [],
    3: [],
  };

  authState.user?.profile?.current_topic_ratings.forEach((rating) => {
    const backpackTopic = backpackTopics.find(
      (backpackTopic) => backpackTopic.id === rating.young_person_transition_topic_id
    );
    const unsureTopic = unsureTopics.find(
      (unsureTopic) => unsureTopic.id === rating.young_person_transition_topic_id
    );
    const tentTopic = tentTopics.find(
      (tentTopic) => tentTopic.id === rating.young_person_transition_topic_id
    );
    if (backpackTopic) {
      ratedBackpackTopicsByChallenge[rating.challenge_rating].push(backpackTopic);
    } else if (unsureTopic) {
      ratedUnsureTopicsByChallenge[rating.challenge_rating].push(unsureTopic);
    } else if (tentTopic) {
      ratedTentTopicsByChallenge[rating.challenge_rating].push(tentTopic);
    }
  });

  useEffect(() => {
    const init = async () => {
      await fetchYoungPersonTransitionCategories();
    };
    init();
  }, []);

  const fetchYoungPersonTransitionCategories = async () => {
    const categoriesResponse = await axios.get(YOUNG_PERSON_TRANSITION_CATEGORIES_URL);
    setCategories(categoriesResponse.data);
  };

  const saveTopicRating = async (rating) => {
    await axios.post('/api/transition/rate-topic', rating);
    await fetchUser();
  };

  const value = {
    categories: categoriesWithTopicRatings,
    saveTopicRating,
    ratedBackpackTopicsByChallenge,
    ratedUnsureTopicsByChallenge,
    ratedTentTopicsByChallenge,
    unsureTopics,
    criticalTopics,
    unratedCriticalTopics,
    getNextUnratedTopicWithCurrentCategory,
    userRatedAllAndOnlyCriticalTopics,
  };

  return (
    <YoungPersonTransitionContext.Provider value={value}>
      {children}
    </YoungPersonTransitionContext.Provider>
  );
}

const useYoungPersonTransition = () => useContext(YoungPersonTransitionContext);

export {YoungPersonTransitionProvider, useYoungPersonTransition};
