import React from 'react';
import Divider from '@mui/material/Divider';

import {YOUNG_PERSON_CATEGORY_TOPIC_ICONS} from './transitionIcons';

const SummaryChallengeSection = ({topicsForChallengeRating, subtitle, icon}) =>
  topicsForChallengeRating.length > 0 && (
    <>
      <div className="summary-challenge-section">
        <div className="summary-challenge-section-header">
          <img src={icon} alt="" height={24} width={24} />
          <div className="semibold">{subtitle}</div>
        </div>
        <div className="topics-container">
          {topicsForChallengeRating?.map((topic) => (
            <div key={topic.id} className="summary-challenge-section-topic">
              <img
                src={YOUNG_PERSON_CATEGORY_TOPIC_ICONS[topic.key]}
                width={40}
                height={40}
                style={{marginRight: 8}}
                alt=""
              />
              <div>{topic.name}</div>
            </div>
          ))}
        </div>
      </div>
      <Divider aria-hidden="true" />
    </>
  );

export default SummaryChallengeSection;
