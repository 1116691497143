import React from 'react';
import {Box, Typography} from '@mui/material';

const CategoryState = ({icon, text}) => (
  <div style={{display: 'flex'}}>
    <Box component="img" src={icon} width={20} height={20} sx={{mr: 1}} />
    <Typography variant="body2">{text}</Typography>
  </div>
);

export default CategoryState;
