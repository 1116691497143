import {useEffect, useState} from 'react';

import {useAuth} from '../Auth/AuthProvider';

const useYoungPersonProfileCopy = () => {
  const {authState} = useAuth();

  const [youngPersonProfile, setYoungPersonProfile] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Make a copy of youngPerson to keep our unsaved changes
    // (check for id to wait for youngPerson to be loaded)
    if (authState.user?.profile?.id) {
      setYoungPersonProfile({
        ...authState.user.profile,
        custom_additional_support_need_categories:
          authState.user.profile.custom_additional_support_need_categories.map(
            (need) => need.name ?? need
          ),
      });
      setIsLoading(false);
    }
  }, [authState.user?.profile]);
  return {isLoading, youngPersonProfile, setYoungPersonProfile};
};

export default useYoungPersonProfileCopy;
