import React from 'react';
import {Box, Typography} from '@mui/material';

const PrivacyRight = ({name, description}) => (
  <Box sx={{mb: 2}}>
    <Typography variant="h3" gutterBottom>
      {name}
    </Typography>
    <Typography paragraph component="div">
      {description}
    </Typography>
  </Box>
);

export default PrivacyRight;
