import React, {useState, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Link, Box, Button, Container, Divider, Typography} from '@mui/material';

import {useAuth} from '../Auth/AuthProvider';
import LandingVisual from '../img/yp-landing-page.svg';
import AccountDeletedDialog from '../Account/AccountDeletedtDialog';
import {PARENT_BASE_URL} from '../Util/constants';

const Start = () => {
  const {authState, dispatch} = useAuth();
  const [shouldShowDeletedDialog, setShouldShowDeletedDialog] = useState(false);
  useEffect(() => {
    if (authState.isDeleted) {
      setShouldShowDeletedDialog(true);
    } else {
      setShouldShowDeletedDialog(false);
    }
  }, [authState.isDeleted]);

  return (
    <Container maxWidth="md" sx={{py: 4, px: 2}}>
      {authState.isDeleted && (
        <AccountDeletedDialog
          isVisible={shouldShowDeletedDialog}
          closeDialog={async () => {
            await dispatch({type: 'SET_IS_DELETED', payload: false});
            setShouldShowDeletedDialog(false);
          }}
        />
      )}
      <Typography variant="h1">
        <Box
          component="img"
          src={LandingVisual}
          alt="Welcome to Compass"
          sx={{
            display: 'block',
            width: '100%',
            height: {xs: 192, sm: 240, md: 280},
          }}
        />
      </Typography>
      <Box sx={{my: 2, textAlign: 'left'}}>
        <Typography paragraph>
          Compass helps you prepare for big changes in life - like leaving school, getting a job, or
          changing health or care services.
        </Typography>
        <Typography paragraph>
          Compass makes it easy for you to understand stuff that is important to you, and things you
          might want to learn a bit more about.
        </Typography>
        <Typography paragraph>
          Compass helps you have better conversations with people who are helping you take your next
          big step.
        </Typography>
      </Box>
      <Box display="flex" justifyContent={'center'} sx={{my: 2}}>
        <Button
          variant="contained"
          color="secondary"
          component={RouterLink}
          to="/your-data"
          sx={{mr: 2}}>
          Sign up
        </Button>
        <Button variant="outlined" color="secondary" component={RouterLink} to="/login">
          Log In
        </Button>
      </Box>
      <Typography align="center" gutterBottom>
        Parent or carer? Try <Link href={PARENT_BASE_URL}>Compass for Parents/Carers</Link>
      </Typography>
      <Divider sx={{my: 3}} />
      <Typography paragraph>
        View Compass {` `}
        <Link component={RouterLink} to="/privacy" target="_blank">
          Privacy Notice
        </Link>
      </Typography>
    </Container>
  );
};

export default Start;
