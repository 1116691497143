import React from 'react';
import {Box, Typography} from '@mui/material';

const ProcessingType = ({name, purpose, shared}) => (
  <Box sx={{mb: 2}}>
    <Typography variant="h3" gutterBottom>
      {name}
    </Typography>
    <Typography variant="h4" sx={{textDecoration: 'underline'}}>
      Purpose:
    </Typography>
    <Typography paragraph sx={{p: 1}}>
      {purpose}
    </Typography>
    <Typography variant="h4" sx={{textDecoration: 'underline'}}>
      Shared with another controller?
    </Typography>
    <Typography paragraph component="div" sx={{p: 1}}>
      {shared}
    </Typography>
  </Box>
);

export default ProcessingType;
