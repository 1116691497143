import React from 'react';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const EvaluationThemeStepper = ({currentThemeIndex, themes}) => {
  return (
    <Stepper activeStep={currentThemeIndex}>
      {themes.map((theme, index) => (
        <Step id={`stepper-${index}`} key={theme.id}>
          <StepLabel />
        </Step>
      ))}
    </Stepper>
  );
};

export default EvaluationThemeStepper;
