import React from 'react';
import {Dialog, Button, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import {onCloseUnlessBackdropClick} from '../Util/helpers';
import {useEvaluation} from '../Evaluation/EvaluationProvider';

const YoungPersonEvaluationPromptDialog = ({isVisible, closeDialog}) => {
  const navigate = useNavigate();
  const {evaluationState, isLoading} = useEvaluation();

  const handleStart = async () => {
    closeDialog();
    if (evaluationState.evaluations?.length > 0) {
      navigate('/feedback/about');
    } else {
      navigate('/feedback/about-you');
    }
  };

  return (
    <Dialog
      open={isVisible}
      onClose={(event, reason) => onCloseUnlessBackdropClick(event, reason, closeDialog)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>Time to tell us what you think!</DialogTitle>
      <DialogContent>
        <Typography paragraph>
          We would like to hear some of your thoughts on how things are going for you.
        </Typography>
        <Typography paragraph>
          You should tell us what is going well, and what you think needs to get better.
        </Typography>
        <Typography paragraph>If now is not a good time, we can ask you again later.</Typography>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={closeDialog} sx={{mr: 1}}>
            Skip
          </Button>
          <Button disabled={isLoading} variant="contained" color="secondary" onClick={handleStart}>
            Start
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default YoungPersonEvaluationPromptDialog;
