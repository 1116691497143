import React from 'react';
import {Dialog, Button, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';

import {onCloseUnlessBackdropClick} from '../Util/helpers';

const AccountDeletedDialog = ({isVisible, closeDialog}) => (
  <Dialog
    open={isVisible}
    onClose={(event, reason) => onCloseUnlessBackdropClick(event, reason, closeDialog)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
    <DialogTitle>Account Deleted</DialogTitle>
    <DialogContent>
      <Typography paragraph>Your account has now been deleted.</Typography>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={closeDialog}>
          Dismiss
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
);

export default AccountDeletedDialog;
