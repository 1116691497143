import React from 'react';

import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Typography} from '@mui/material';

import {YOUNG_PERSON_DISABILITY_CHALLENGE_LEVEL} from '../Util/constants';

const AboutYouChallengeLevel = ({challengeLevel, onChange}) => (
  <>
    <Typography variant="h3" gutterBottom>
      How challenging does your additional support need or disability make life for you?
    </Typography>
    <Typography paragraph variant="body2">
      Choose one of the options.
    </Typography>
    <RadioGroup
      aria-label="challenge-level-radio-group"
      name="challenge-level-radio-group"
      value={challengeLevel}
      onChange={(event) => {
        onChange(event.target.value);
      }}>
      <FormControlLabel
        label={YOUNG_PERSON_DISABILITY_CHALLENGE_LEVEL[1]}
        value="1"
        control={<Radio color="secondary" />}
      />
      <FormControlLabel
        label={YOUNG_PERSON_DISABILITY_CHALLENGE_LEVEL[2]}
        value="2"
        control={<Radio color="secondary" />}
      />
      <FormControlLabel
        label={YOUNG_PERSON_DISABILITY_CHALLENGE_LEVEL[3]}
        value="3"
        control={<Radio color="secondary" />}
      />
    </RadioGroup>
  </>
);

export default AboutYouChallengeLevel;
