import {Box} from '@mui/material';
import React from 'react';

const CategoryCarouselButtons = ({categoryArray, currentIndex, setCurrentIndex}) => {
  return (
    <Box sx={{display: 'flex', justifyContent: 'center'}}>
      {categoryArray &&
        categoryArray.map((_, index) => (
          <button
            key={`categories-carousel-button-${index}`}
            className={`button-reset categories-carousel-button ${
              currentIndex === index ? 'current' : ''
            }`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
    </Box>
  );
};

export default CategoryCarouselButtons;
