import {useTheme} from '@mui/system';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {sectionIcons} from '../Util/constants';
import YPHomeScreenCardWide from './YPHomeScreenCardWide';

const {transitionTopicCategoryIcons} = sectionIcons;

const HomeTopicsCard = () => {
  const theme = useTheme();
  const {primary} = theme.palette;
  const firstBg = primary.light;
  const navigate = useNavigate();
  return (
    <YPHomeScreenCardWide
      id="transition-topics-card"
      onClick={() => navigate('/categories')}
      backgroundColor={firstBg}
      icon={<img src={transitionTopicCategoryIcons} height={80} alt="" />}
      description={'Look at helpful information and decide what matters to you'}
    />
  );
};

export default HomeTopicsCard;
