import React from 'react';
import {Divider, Box, Paper, Typography} from '@mui/material';

import {YOUNG_PERSON_FEEDBACK_RATING_SCALE} from '../Util/constants';

const PreviousEvaluationQuestionResponse = ({response}) => {
  const [responseKey, {icon: ratingIcon}] = Object.entries(YOUNG_PERSON_FEEDBACK_RATING_SCALE).find(
    ([_, {value}]) => value === response.response_rating
  );
  return (
    <div className="eval-question">
      <Paper className="eval-question-content" sx={{borderRadius: 5}}>
        <Typography>{response.evaluation_question.title}</Typography>
        <Divider aria-hidden="true" />
        <div className="eval-item">
          <Typography variant="body2">
            You said <b>{responseKey.toLowerCase()}</b>
            <Box
              component="img"
              aria-hidden="true"
              sx={{ml: 1, verticalAlign: 'middle'}}
              src={ratingIcon}
              height={20}
            />
          </Typography>
        </div>
      </Paper>
    </div>
  );
};

export default PreviousEvaluationQuestionResponse;
