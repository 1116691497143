import React from 'react';
import {useTheme} from '@mui/material/styles';

import {useAuth} from '../Auth/AuthProvider';
import EvaluationOtherComments from '../Evaluation/EvaluationOtherComments';
import PageHeader from '../Util/PageHeader';
import feedbackIcon from '../img/EvaluationLight.svg';
import {Typography} from '@mui/material';

const YoungPersonEvaluationOtherComments = () => {
  const theme = useTheme();
  const {authState} = useAuth();
  const transitionTypeTitle =
    authState?.user?.profile?.transition_type?.name?.toLowerCase() || 'your experience';

  return (
    <EvaluationOtherComments
      pageHeader={
        <PageHeader
          color={theme.palette.pink}
          name="Any other comments?"
          icon={feedbackIcon}
          backTo="/feedback"
        />
      }
      content={
        <>
          <Typography paragraph>
            Is there anything else you'd like to share about {transitionTypeTitle}? Your comments
            won't be shared directly with your Local Authority, but ARC may read these comments and
            choose to share insights with them.
          </Typography>
          <Typography paragraph sx={{fontStyle: 'italic'}}>
            Please do not include names or any other personal information in these comments.
          </Typography>
        </>
      }
    />
  );
};

export default YoungPersonEvaluationOtherComments;
