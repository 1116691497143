import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Container} from '@mui/material';

import {sectionIcons} from '../Util/constants';
import PageHeader from '../Util/PageHeader';
import {useYoungPersonTransition} from './YoungPersonTransitionProvider';
import RatedTopicDetails from './RatedTopicDetails';

const YoungPersonViewTransitionTopic = () => {
  const {categoryId, topicId} = useParams();
  const theme = useTheme();
  const {categories} = useYoungPersonTransition();
  const [currentCategoryTopic, setCurrentCategoryTopic] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (categories.length > 0) {
      const currentCategory = categories.find((category) => category.id === parseInt(categoryId));
      const newCurrentTopic = currentCategory.topics.find(
        (topic) => topic.id === parseInt(topicId)
      );
      setCurrentCategoryTopic(newCurrentTopic);
      setIsLoading(false);
    }
  }, [categories]);
  return (
    <>
      <PageHeader
        name={'Topic'}
        color={theme.palette.primary.light}
        icon={sectionIcons.transitionTopicsIcon}
        backTo={`/categories/${categoryId}`}
      />
      <Container maxWidth="md" sx={{py: 6}}>
        {currentCategoryTopic && <RatedTopicDetails topic={currentCategoryTopic} />}
        {isLoading && <p>Loading...</p>}
      </Container>
    </>
  );
};

export default YoungPersonViewTransitionTopic;
