import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import {useTheme} from '@mui/material/styles';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import GridOnIcon from '@mui/icons-material/GridOn';

import {useYoungPersonTransition} from './YoungPersonTransitionProvider';
import {
  TOPIC_IMPORTANCE_RATINGS,
  YP_TOPIC_RATING_SUMMARY_DOWNLOAD_LINK_CSV,
  YP_TOPIC_RATING_SUMMARY_DOWNLOAD_LINK_PDF,
} from '../Util/constants';
import summaryIcon from '../img/summary.svg';
import {DialogTransition} from '../Util/helpers';
import SummaryImportanceSection from './SummaryImportanceSection';
import DialogSectionHeaderButton from './DialogSectionHeaderButton';
import useDialogQuery from '../Util/useDialogQuery';
import {Box, Container, Paper, Typography} from '@mui/material';

const SummaryDialog = () => {
  const {ratedBackpackTopicsByChallenge, ratedUnsureTopicsByChallenge, ratedTentTopicsByChallenge} =
    useYoungPersonTransition();

  const theme = useTheme();
  const [backpackTopicsAreVisible, setBackpackTopicsAreVisible] = useState(false);
  const [unsureTopicsAreVisible, setUnsureTopicsAreVisible] = useState(false);
  const [tentTopicsAreVisible, setTentTopicsAreVisible] = useState(false);
  const {closeDialog} = useDialogQuery();
  return (
    <Dialog
      fullScreen
      open={true}
      onClose={closeDialog}
      TransitionComponent={DialogTransition}
      PaperProps={{
        sx: {backgroundColor: 'background.default'},
      }}>
      <Container maxWidth="md" sx={{py: 4}}>
        <Toolbar>
          <div style={{display: 'flex', flex: 1}}></div>
          <IconButton edge="start" onClick={closeDialog} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <div className="dialog-header">
          <img src={summaryIcon} height={80} width={80} alt="" />
          <Typography variant="h2" component="h1">
            Your ratings so far...
          </Typography>
        </div>

        <Paper sx={{mb: 4, p: 2, borderRadius: 5}}>
          <Typography paragraph>Here are the topics you rated so far in a list.</Typography>
          <Typography paragraph>
            You can use the list to show people what is important to you.
          </Typography>
          <Typography paragraph>
            It will help people understand what is important to you when you planning for your
            future.
          </Typography>
          <Typography paragraph>
            You might want to talk about the list with someone you trust so they can help you.
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              href={YP_TOPIC_RATING_SUMMARY_DOWNLOAD_LINK_PDF}
              variant="contained"
              color="secondary"
              sx={{mr: 2}}
              endIcon={<PictureAsPdfIcon />}
              download>
              Download PDF
            </Button>
            <Button
              href={YP_TOPIC_RATING_SUMMARY_DOWNLOAD_LINK_CSV}
              variant="contained"
              color="secondary"
              endIcon={<GridOnIcon />}
              download>
              Download Spreadsheet
            </Button>
          </Box>
        </Paper>
        <div className="left-aligned">
          <DialogSectionHeaderButton
            onClick={() => setBackpackTopicsAreVisible(!backpackTopicsAreVisible)}
            colour={theme.palette.ratings.yes}
            icon={TOPIC_IMPORTANCE_RATINGS[1].whiteIcon}
            title="Topics that matter to you"
            sectionContentsAreVisible={backpackTopicsAreVisible}
          />
          {backpackTopicsAreVisible && (
            <SummaryImportanceSection
              ratedTopicsByChallenge={ratedBackpackTopicsByChallenge}
              textIfEmpty="You have not said which topics matter to you right now"
            />
          )}

          <DialogSectionHeaderButton
            onClick={() => setUnsureTopicsAreVisible(!unsureTopicsAreVisible)}
            colour={theme.palette.mono.pale}
            icon={TOPIC_IMPORTANCE_RATINGS[2].whiteIcon}
            title="Topics you were not sure about"
            sectionContentsAreVisible={unsureTopicsAreVisible}
          />
          {unsureTopicsAreVisible && (
            <SummaryImportanceSection
              ratedTopicsByChallenge={ratedUnsureTopicsByChallenge}
              textIfEmpty="You have not said you were not sure about any topics yet"
            />
          )}

          <DialogSectionHeaderButton
            onClick={() => setTentTopicsAreVisible(!tentTopicsAreVisible)}
            colour={theme.palette.ratings.no}
            icon={TOPIC_IMPORTANCE_RATINGS[3].whiteIcon}
            title="Topics to think about later"
            sectionContentsAreVisible={tentTopicsAreVisible}
          />
          {tentTopicsAreVisible && (
            <SummaryImportanceSection
              ratedTopicsByChallenge={ratedTentTopicsByChallenge}
              textIfEmpty="You have not said which topics you would like to think about later"
            />
          )}
        </div>
      </Container>
    </Dialog>
  );
};

export default SummaryDialog;
