import React from 'react';
import {Typography} from '@mui/material';
import LocalPhoneTwoToneIcon from '@mui/icons-material/LocalPhoneTwoTone';

import Register from '../Auth/Register';
import EmailIcon from '../img/email.svg';
import PasswordIcon from '../img/password.svg';
import signUpGraphic from '../img/sign-up.svg';
import {RegisterIcon} from '../Auth/RegisterIcon';

const RegisterScreen = () => (
  <Register
    title="Sign Up"
    nameHeader={
      <>
        <RegisterIcon>
          <img src={signUpGraphic} height={160} />
        </RegisterIcon>
        <Typography variant="h3" fontWeight={600} gutterBottom>
          Hello! Please type in your name.
        </Typography>
      </>
    }
    emailHeader={
      <>
        <RegisterIcon>
          <img src={EmailIcon} height={60} width={60} />
        </RegisterIcon>
        <Typography variant="h3" fontWeight={600} gutterBottom>
          Please type in your email address.
        </Typography>
      </>
    }
    phoneHeader={
      <>
        <RegisterIcon>
          <LocalPhoneTwoToneIcon sx={{height: 80, width: 80}} color="primary" />
        </RegisterIcon>
        <Typography variant="h3" fontWeight={600} gutterBottom>
          What's your mobile number? (Optional)
        </Typography>
        <Typography paragraph>
          We use this to send you helpful notifications from Compass. You can switch these
          notifications off later in your account settings.
        </Typography>
      </>
    }
    passwordHeader={
      <>
        <RegisterIcon>
          <img src={PasswordIcon} height={60} width={60} />
        </RegisterIcon>
        <Typography variant="h3" fontWeight={600} gutterBottom>
          Create a password
        </Typography>
      </>
    }
    showPhoneField={true}
  />
);

export default RegisterScreen;
