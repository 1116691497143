import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import {Button, Container, ListItemButton} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import {useAuth} from '../Auth/AuthProvider';
import useDialogQuery from '../Util/useDialogQuery';
import {TOPIC_IMPORTANCE_RATINGS, FEEDBACK_STRINGS, DIALOG_URL_PARAMS} from '../Util/constants';
import HomeIcon from '../img/MenuIcons/Home.svg';
import youngPersonLogo from '../img/logo/logo_young_person.svg';
import youngPersonLogoDark from '../img/logo/logo_young_person_dark.svg';
import MenuIcon from '../img/MenuIcons/Menu.svg';
import FeedbackIcon from '../img/MenuIcons/Feedback-yp.svg';
import ContactUsIcon from '../img/MenuIcons/ContactUs.svg';
import LogoutIcon from '../img/MenuIcons/Logout.svg';
import AccountIcon from '../img/MenuIcons/Account.svg';
import {useDarkModeTheme} from '../DarkModeThemeProvider';

const navIconStyle = {display: 'flex', flexDirection: 'column'};

const NavBar = () => {
  const {authState, showLogoutDialog} = useAuth();
  const {isDarkMode, setIsDarkMode} = useDarkModeTheme();
  const {openDialog} = useDialogQuery();

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const handleDrawerOpen = () => {
    setDrawerIsOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerIsOpen(false);
  };

  const navigate = useNavigate();

  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Container maxWidth="md">
          <Toolbar disableGutters>
            {authState.isAuthenticated && (
              <>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => navigate('/')}
                  sx={navIconStyle}
                  id="home-button">
                  <img src={HomeIcon} height={24} />
                  <div style={{fontSize: 11, letterSpacing: 0.33}}>Home</div>
                </IconButton>
                <div className="compass-logo-container">
                  <button className="button-reset" onClick={() => navigate('/')}>
                    <img
                      src={isDarkMode ? youngPersonLogoDark : youngPersonLogo}
                      height={35}
                      alt="Compass Homepage"
                    />
                  </button>
                </div>
                <IconButton
                  id="menu-button"
                  edge="start"
                  color="inherit"
                  onClick={handleDrawerOpen}
                  sx={navIconStyle}>
                  <img src={MenuIcon} height={24} />
                  <div style={{fontSize: 11, letterSpacing: 0.33}}>Menu</div>
                </IconButton>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer anchor={'right'} open={drawerIsOpen} onClose={handleDrawerClose}>
        <div role="presentation" onClick={handleDrawerClose} style={{minWidth: 250}}>
          <div className="nav-bar-header">
            <h2 className="header-4">Menu</h2>
            <IconButton onClick={() => setIsDarkMode(!isDarkMode)} sx={{ml: 'auto'}}>
              <Brightness4Icon />
            </IconButton>
          </div>
          <Divider aria-hidden="true" />
          <List>
            {authState.user.hasOnboarded && (
              <>
                <ListItemButton
                  onClick={() => {
                    openDialog(DIALOG_URL_PARAMS.BACKPACK);
                    handleDrawerClose();
                  }}>
                  <ListItemIcon>
                    <img src={TOPIC_IMPORTANCE_RATINGS[1].icon} height={24} width={24} />
                  </ListItemIcon>
                  <ListItemText primary={'Topics that matter to you'} />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    openDialog(DIALOG_URL_PARAMS.UNSURE);
                    handleDrawerClose();
                  }}>
                  <ListItemIcon>
                    <img src={TOPIC_IMPORTANCE_RATINGS[2].icon} height={24} width={24} />
                  </ListItemIcon>
                  <ListItemText primary={'Topics that you are unsure about'} />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    openDialog(DIALOG_URL_PARAMS.TENT);
                    handleDrawerClose();
                  }}>
                  <ListItemIcon>
                    <img src={TOPIC_IMPORTANCE_RATINGS[3].icon} height={24} width={24} />
                  </ListItemIcon>
                  <ListItemText primary={'Topics to think about later'} />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate('/feedback');
                    handleDrawerClose();
                  }}>
                  <ListItemIcon>
                    <img src={FeedbackIcon} height={24} width={24} />
                  </ListItemIcon>
                  <ListItemText primary={FEEDBACK_STRINGS.youngPerson.feedbackTitle} />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate('/my-account');
                    handleDrawerClose();
                  }}>
                  <ListItemIcon>
                    <img src={AccountIcon} height={24} width={24} />
                  </ListItemIcon>
                  <ListItemText primary={'My Account'} />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    navigate('/contact-us');
                    handleDrawerClose();
                  }}>
                  <ListItemIcon>
                    <img src={ContactUsIcon} height={24} width={24} />
                  </ListItemIcon>
                  <ListItemText primary={'Contact Us'} />
                </ListItemButton>
              </>
            )}
            <ListItemButton
              onClick={() => {
                handleDrawerClose();
                showLogoutDialog();
              }}>
              <ListItemIcon>
                <img src={LogoutIcon} height={24} width={24} />
              </ListItemIcon>
              <ListItemText primary={'Logout'} />
            </ListItemButton>
            {authState.user.hasOnboarded && (
              <>
                <Divider />
                <ListItem>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      navigate('/crisis');
                      handleDrawerClose();
                    }}>
                    Emergency Information
                  </Button>
                </ListItem>
              </>
            )}
          </List>
        </div>
      </Drawer>
    </>
  );
};

export default NavBar;
