import React from 'react';
import {useTheme} from '@mui/system';
import {Button, Checkbox, Container, FormControlLabel, Paper, Typography} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';

import PageHeader from '../Util/PageHeader';
import {TOPIC_IMPORTANCE_RATINGS, sectionIcons} from '../Util/constants';
import {useYoungPersonTransition} from './YoungPersonTransitionProvider';
import {YOUNG_PERSON_CATEGORY_TOPIC_ICONS} from './transitionIcons';
import arrowGraphic from '../img/arrow.svg';
import {useAuth} from '../Auth/AuthProvider';

const YoungPersonRatedTransitionTopic = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {authState, updateYoungPersonProfile} = useAuth();
  const {categoryId, topicId} = useParams();
  const {
    categories,
    unratedCriticalTopics,
    getNextUnratedTopicWithCurrentCategory,
    userRatedAllAndOnlyCriticalTopics,
  } = useYoungPersonTransition();

  const currentCategory = categories.find((category) => category.id === parseInt(categoryId));
  const currentTopic = currentCategory?.topics.find((topic) => topic.id === parseInt(topicId));

  const importanceRating = currentTopic?.rating.importance_rating;
  const shouldSkipTopicRatedScreen = !!authState.user?.profile?.should_skip_topic_rated_screen;
  const nextTopicToRate = getNextUnratedTopicWithCurrentCategory(categoryId);

  const handleRateAnotherTopic = async () => {
    if (userRatedAllAndOnlyCriticalTopics) {
      // If we just rated the last critical topics
      navigate('/critical-topics');
    } else if (nextTopicToRate) {
      navigate(
        `/categories/${nextTopicToRate.transition_category_id}/topics/${nextTopicToRate.id}/rate`
      );
    } else {
      // If we just finished rating all the critical topics
      navigate(`/categories/${categoryId}/complete`);
    }
  };

  const handleReturnToTopicList = async () => {
    if (userRatedAllAndOnlyCriticalTopics || unratedCriticalTopics.length > 0) {
      // If we just rated the last critical topics
      navigate('/critical-topics');
    } else {
      navigate(`/categories/${categoryId}`);
    }
  };

  return (
    <>
      <PageHeader
        name={'Rate Topic'}
        color={theme.palette.yellow}
        icon={sectionIcons.ratingsIcon}
        backTo={
          unratedCriticalTopics.length > 0
            ? '/critical-topics'
            : `/categories/${currentTopic?.transition_category_id}`
        }
      />
      <Container maxWidth="md" sx={{py: 6}}>
        {currentTopic && (
          <>
            <Typography variant="h3" align="center" gutterBottom>
              You have rated this topic!
            </Typography>

            <Paper
              className="topic-card topic-rated-graphic-container"
              sx={{
                borderRadius: 5,
              }}>
              <div className="centre-aligned">
                <img
                  src={YOUNG_PERSON_CATEGORY_TOPIC_ICONS[currentTopic.key]}
                  width={60}
                  height={60}
                  alt=""
                />
                <Typography variant="body2">{currentTopic.name}</Typography>
              </div>
              <img src={arrowGraphic} id="rated-topic-arrow" alt="" />
              <img
                className="rated-icon"
                src={TOPIC_IMPORTANCE_RATINGS[importanceRating].icon}
                height={75}
                alt=""
              />
            </Paper>

            <Paper
              className="topic-card"
              sx={{
                borderRadius: 5,
              }}>
              <Typography variant="body2" paragraph>
                You can find <span className="bold">{currentTopic.name}</span> in{' '}
                {TOPIC_IMPORTANCE_RATINGS[importanceRating].storedIn}.
              </Typography>
              <Typography variant="body2" paragraph>
                You can change your ratings for <span className="bold">{currentTopic.name}</span>{' '}
                any time you want to.
              </Typography>
            </Paper>

            <FormControlLabel
              label="Do not show me where topics are saved again."
              control={
                <Checkbox
                  value={shouldSkipTopicRatedScreen}
                  checked={shouldSkipTopicRatedScreen}
                  color="secondary"
                  onChange={async (event) => {
                    try {
                      await updateYoungPersonProfile({
                        id: authState.user?.profile?.id,
                        should_skip_topic_rated_screen: event.target.checked,
                      });
                    } catch {
                      alert('There was a problem saving your preference, please try again later.');
                    }
                  }}
                />
              }
            />

            {nextTopicToRate && (
              <div className="right-aligned-button">
                <Button variant="contained" color="secondary" onClick={handleRateAnotherTopic}>
                  Rate another topic
                </Button>
              </div>
            )}
            <div className="right-aligned-button">
              <Button variant="outlined" color="secondary" onClick={handleReturnToTopicList}>
                Return to topic list
              </Button>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default YoungPersonRatedTransitionTopic;
