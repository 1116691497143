import React from 'react';
import {Navigate, Route} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import {SentryRoutes} from '../Sentry';

import NavBar from './YPNavBar';
import UnauthenticatedNavBar from '../Nav/UnauthenticatedNavBar';

import {useAuth} from '../Auth/AuthProvider';
import {EvaluationProvider} from '../Evaluation/EvaluationProvider';
import {OnboardingProvider} from '../Onboarding/OnboardingProvider';
import {YoungPersonTransitionProvider} from './YoungPersonTransitionProvider';
import {usePageTracking, MATOMO_COMPASS_APPLICATIONS} from '../MatomoAnalytics';

import YoungPersonEvaluationAbout from './YoungPersonEvaluationAbout';
import YoungPersonEvaluationAboutYou from './YoungPersonEvaluationAboutYou';
import YoungPersonEvaluationPromptDialog from './YoungPersonEvaluationPromptDialog';
import ForgotPassword from '../Auth/ForgotPassword';
import ResetPassword from '../Auth/ResetPassword';
import YoungPersonHome from './YoungPersonHome';
import YoungPersonLandingPage from './YoungPersonLandingPage';
import Login from '../Auth/Login';
import signInGraphic from '../img/sign-in.svg';
import YoungPersonNewFeedbackScreen from './YoungPersonNewFeedbackScreen';
import YoungPersonPrivacyNotice from './YoungPersonPrivacyNotice';
import YoungPersonRegisterScreen from './YoungPersonRegisterScreen';
import YoungPersonFeedbackScreen from './YoungPersonFeedbackScreen';
import YoungPersonCompletedEvaluationScreen from './YoungPersonCompletedEvaluationScreen';
import YoungPersonEvaluationHistoryScreen from './YoungPersonEvaluationHistoryScreen';
import YoungPersonPreviousEvaluation from './YoungPersonPreviousEvaluation';
import YoungPersonEvaluationOtherComments from './YoungPersonEvaluationOtherComments';
import ContactUs from '../ContactUs';
import YoungPersonOnboarding from './Onboarding/YoungPersonOnboarding';
import {DIALOG_URL_PARAMS} from '../Util/constants';
import youngPersonLogo from '../img/logo/logo_young_person.svg';
import youngPersonLogoDark from '../img/logo/logo_young_person_dark.svg';
import YoungPersonTransitionCategoryList from './YoungPersonTransitionCategoryList';
import YoungPersonTransitionCategoryComplete from './YoungPersonTransitionCategoryComplete';
import YoungPersonTransitionCategoryTopicsList from './YoungPersonTransitionCategoryTopicsList';
import YoungPersonViewTransitionTopic from './YoungPersonViewTransitionTopic';
import YoungPersonRateTransitionTopic from './YoungPersonRateTransitionTopic';
import YoungPersonCriticalTopics from './YoungPersonCriticalTopics';
import YoungPersonYourPersonalDataScreen from './YoungPersonYourPersonalDataScreen';
import BackpackDialog from './BackpackDialog';
import TentDialog from './TentDialog';
import SummaryDialog from './SummaryDialog';
import YoungPersonAccount from './YoungPersonAccount';
import DeleteAccount from '../Account/DeleteAccount';
import EditAccountScreen from '../Account/EditAccountScreen';
import {
  AdditionalSupportNeeds,
  BigChange,
  BigChangeDate,
  ChallengeLevel,
  DoB,
  Identify,
  LocalCouncil,
} from './EditYoungPersonAccountInfo';
import YoungPersonCrisisHelp from './YoungPersonCrisisHelp';
import UnsureTopicsDialog from './UnsureTopicsDialog';
import useDialogQuery from '../Util/useDialogQuery';
import CookieConsentBanner from '../Util/CookieConsentBanner';
import YoungPersonRatedTransitionTopic from './YoungPersonRatedTransitionTopic';
import UpdateBigChange from './UpdateBigChange';
import StoreRedirectUrlForAuth from '../Util/StoreRedirectUrlForAuth';

const queryClient = new QueryClient();

const App = () => {
  const {authState} = useAuth();
  usePageTracking(MATOMO_COMPASS_APPLICATIONS.YOUNG_PERSON);

  return (
    <>
      {authState.isCheckingAuth ? (
        <div>Loading</div>
      ) : authState.isAuthenticated ? (
        <AuthenticatedApp />
      ) : (
        <UnauthenticatedApp />
      )}
      <CookieConsentBanner />
    </>
  );
};

const AuthenticatedApp = () => {
  const {dialogQuery} = useDialogQuery();
  return (
    <QueryClientProvider client={queryClient}>
      <EvaluationProvider EvaluationPromptDialog={YoungPersonEvaluationPromptDialog}>
        <NavBar />
        <OnboardingProvider Onboarding={YoungPersonOnboarding}>
          <YoungPersonTransitionProvider>
            <main>
              <SentryRoutes>
                <Route path="/" element={<YoungPersonHome />} />
                <Route path="/categories" element={<YoungPersonTransitionCategoryList />} />
                <Route path="critical-topics" element={<YoungPersonCriticalTopics />} />
                <Route
                  path="/categories/:categoryId"
                  element={<YoungPersonTransitionCategoryTopicsList />}
                />
                <Route
                  path="/categories/:categoryId/complete"
                  element={<YoungPersonTransitionCategoryComplete />}
                />
                <Route
                  path="/categories/:categoryId/topics/:topicId"
                  element={<YoungPersonViewTransitionTopic />}
                />
                <Route
                  path="/categories/:categoryId/topics/:topicId/rate"
                  element={<YoungPersonRateTransitionTopic />}
                />
                <Route
                  path="/categories/:categoryId/topics/:topicId/rated"
                  element={<YoungPersonRatedTransitionTopic />}
                />
                <Route path="/feedback" element={<YoungPersonFeedbackScreen />} />
                <Route path="/feedback/about-you" element={<YoungPersonEvaluationAboutYou />} />
                <Route path="/feedback/about" element={<YoungPersonEvaluationAbout />} />
                <Route path="/feedback/new" element={<YoungPersonNewFeedbackScreen />} />
                <Route path="/feedback/comments" element={<YoungPersonEvaluationOtherComments />} />
                <Route
                  path="/feedback/complete"
                  element={<YoungPersonCompletedEvaluationScreen />}
                />
                <Route path="/feedback/history" element={<YoungPersonEvaluationHistoryScreen />} />
                <Route
                  path="/feedback/history/:evaluationId"
                  element={<YoungPersonPreviousEvaluation />}
                />
                <Route path="/my-account" element={<YoungPersonAccount />} />
                <Route path="/my-account/delete" element={<DeleteAccount />} />
                <Route
                  path="/my-account/edit/*"
                  element={
                    <EditAccountScreen
                      profileDetails={
                        <>
                          <Route path="dob" element={<DoB />} />
                          <Route path="local-council" element={<LocalCouncil />} />
                          <Route path="big-change" element={<BigChange />} />
                          <Route path="big-change-date" element={<BigChangeDate />} />
                          <Route path="identify" element={<Identify />} />
                          <Route path="challenge-level" element={<ChallengeLevel />} />
                          <Route
                            path="additional-support-needs"
                            element={<AdditionalSupportNeeds />}
                          />
                          <Route path="*" element={<Navigate to="/my-account" />} />
                        </>
                      }
                    />
                  }
                />
                <Route path="/update-big-change" element={<UpdateBigChange />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/privacy" element={<YoungPersonPrivacyNotice />} />
                <Route path="/crisis" element={<YoungPersonCrisisHelp />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </SentryRoutes>
              {dialogQuery === DIALOG_URL_PARAMS.BACKPACK && <BackpackDialog />}
              {dialogQuery === DIALOG_URL_PARAMS.TENT && <TentDialog />}
              {dialogQuery === DIALOG_URL_PARAMS.SUMMARY && <SummaryDialog />}
              {dialogQuery === DIALOG_URL_PARAMS.UNSURE && <UnsureTopicsDialog />}
            </main>
          </YoungPersonTransitionProvider>
        </OnboardingProvider>
      </EvaluationProvider>
    </QueryClientProvider>
  );
};

const UnauthenticatedApp = () => {
  return (
    <>
      <UnauthenticatedNavBar logoLight={youngPersonLogo} logoDark={youngPersonLogoDark} />
      <main>
        <SentryRoutes>
          <Route path="/" element={<YoungPersonLandingPage />} />
          <Route
            path="/login"
            element={<Login title="Sign In" image={<img src={signInGraphic} height={160} />} />}
          />
          <Route path="/your-data" element={<YoungPersonYourPersonalDataScreen />} />
          <Route path="/register" element={<YoungPersonRegisterScreen />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/privacy" element={<YoungPersonPrivacyNotice />} />
          <Route path="*" element={<StoreRedirectUrlForAuth />} />
        </SentryRoutes>
      </main>
    </>
  );
};

export default App;
