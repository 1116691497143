import React from 'react';
import {Card, CardActionArea, CardContent, Typography} from '@mui/material';
import {Box} from '@mui/system';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const YPHomeScreenCardWide = ({
  id,
  onClick,
  disabled = false,
  backgroundColor,
  icon,
  description,
}) => {
  return (
    <Card sx={{borderRadius: 5, mb: 2, cursor: disabled ? 'not-allowed' : 'pointer'}} id={id}>
      <CardActionArea onClick={onClick} disabled={disabled}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            backgroundColor,
            height: 140,
          }}>
          {icon}
        </Box>
        <CardContent sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography variant="h5" sx={{mr: 1}}>
            {description}
          </Typography>
          <ArrowForwardIcon />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default YPHomeScreenCardWide;
