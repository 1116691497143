import React from 'react';

import {Container, Typography, Box} from '@mui/material';
import {useTheme} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import PageHeader from '../Util/PageHeader';
import {sectionIcons} from '../Util/constants';
import TransitionTopicTile from './TransitionTopicTile';
import {useAuth} from '../Auth/AuthProvider';
import {useYoungPersonTransition} from './YoungPersonTransitionProvider';
import HomeTopicsCard from './HomeTopicsCard';

const YoungPersonCriticalTopics = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {authState} = useAuth();
  const transitionTypeTitle = authState.user.profile.transition_type.name;

  const {unratedCriticalTopics} = useYoungPersonTransition();

  return (
    <>
      <PageHeader
        name={`${transitionTypeTitle} topics`}
        color={theme.palette.primary.light}
        icon={sectionIcons.transitionTopicsIcon}
        backTo="/"
      />
      <Container maxWidth="md" sx={{py: 4}}>
        {unratedCriticalTopics.length > 0 ? (
          <>
            <Typography variant="h5" paragraph>
              These topics are very important for {transitionTypeTitle.toLowerCase()}.
            </Typography>
            <Typography variant="h5" paragraph>
              Once you have looked at and rated them, you will be able to access more topics.
            </Typography>
          </>
        ) : (
          <Typography variant="h5" paragraph sx={{mt: 4}}>
            You have rated all the important topics for {transitionTypeTitle.toLowerCase()}. You can
            now access the topics related to other things too.
          </Typography>
        )}

        {unratedCriticalTopics.length === 0 ? (
          <HomeTopicsCard />
        ) : (
          <Box className="topics-container">
            {unratedCriticalTopics.map(({transition_topic: topic}) => (
              <TransitionTopicTile
                key={topic.id}
                topic={topic}
                onClick={() =>
                  navigate(`/categories/${topic.transition_category_id}/topics/${topic.id}/rate`)
                }
                onClickLabel="View topic"
              />
            ))}
          </Box>
        )}
      </Container>
    </>
  );
};

export default YoungPersonCriticalTopics;
