import React from 'react';
import {useTheme} from '@mui/material/styles';

import PageHeader from '../Util/PageHeader';
import {FEEDBACK_STRINGS} from '../Util/constants';
import Evaluation from '../Evaluation/Evaluation';
import EvaluationTheme from './EvaluationTheme';
import feedbackIcon from '../img/EvaluationLight.svg';

const YoungPersonNewFeedbackScreen = () => {
  const theme = useTheme();

  return (
    <Evaluation
      pageHeader={
        <PageHeader
          color={theme.palette.pink}
          name={FEEDBACK_STRINGS.youngPerson.feedbackTitle}
          icon={feedbackIcon}
          backTo="/feedback"
        />
      }
      EvaluationTheme={EvaluationTheme}
    />
  );
};

export default YoungPersonNewFeedbackScreen;
