import React from 'react';
import {useTheme} from '@mui/material/styles';

import feedbackHistoryIcon from '../img/EvaluationHistoryLight.svg';
import PageHeader from '../Util/PageHeader';
import {FEEDBACK_STRINGS} from '../Util/constants';
import EvaluationHistory from '../Evaluation/EvaluationHistory';
import {Typography} from '@mui/material';

const YoungPersonEvaluationHistoryScreen = () => {
  const theme = useTheme();

  return (
    <EvaluationHistory
      pageHeader={
        <PageHeader
          color={theme.palette.pink}
          name={FEEDBACK_STRINGS.youngPerson.feedbackHistoryTitle}
          icon={feedbackHistoryIcon}
          backTo="/feedback"
        />
      }
      content={
        <>
          <Typography paragraph>These are all your past answers.</Typography>
          <Typography paragraph>
            You can see them in more detail by choosing the date you answered the questions. You can
            look at your answers any time you want.
          </Typography>
        </>
      }
    />
  );
};

export default YoungPersonEvaluationHistoryScreen;
