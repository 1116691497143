import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Container} from '@mui/material';

import {FEEDBACK_STRINGS} from '../Util/constants';
import {useAuth} from '../Auth/AuthProvider';

const CompletedEvaluation = ({pageHeader, content}) => {
  const {isParent} = useAuth();
  const {feedbackHistoryTitle} = isParent ? FEEDBACK_STRINGS.parent : FEEDBACK_STRINGS.youngPerson;
  return (
    <>
      {pageHeader}
      <Container maxWidth="md" sx={{py: 6}}>
        {content}
        <Button
          component={Link}
          variant="contained"
          color="secondary"
          to="/feedback/history"
          style={{float: 'right'}}>
          {feedbackHistoryTitle}
        </Button>
      </Container>
    </>
  );
};

export default CompletedEvaluation;
