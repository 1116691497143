import React from 'react';
import {Container} from '@mui/material';

import {compassArrowIcons} from '../Util/constants';

const ViewPreviousOrNextCategoryButtons = ({
  onPreviousClick,
  onNextClick,
  previousButtonDisabled,
  nextButtonDisabled,
}) => (
  <Container maxWidth="md" sx={{display: 'flex', justifyContent: 'space-between', mb: 4}}>
    <button
      className="button-reset prev-or-next-category-button"
      id="previous-category-button"
      onClick={onPreviousClick}
      disabled={previousButtonDisabled}>
      <div className="prev-or-next-category-button-icon">
        <img src={compassArrowIcons.leftFacingArrowIcon.secondary} />
      </div>
      <div>Previous</div>
    </button>
    <button
      className="button-reset prev-or-next-category-button"
      id="next-category-button"
      onClick={onNextClick}
      disabled={nextButtonDisabled}>
      <div>Next</div>
      <div className="prev-or-next-category-button-icon">
        <img src={compassArrowIcons.rightFacingArrowIcon.secondary} />
      </div>
    </button>
  </Container>
);

export default ViewPreviousOrNextCategoryButtons;
