import React from 'react';
import {Typography} from '@mui/material';

import {useYoungPersonTransition} from './YoungPersonTransitionProvider';
import MyTopicsDialog from './MyTopicsDialog';
import {TOPIC_IMPORTANCE_RATINGS} from '../Util/constants';

const BackpackDialog = () => {
  const {ratedBackpackTopicsByChallenge} = useYoungPersonTransition();

  return (
    <MyTopicsDialog
      title="Topics that matter to you"
      ratedTopicsByChallenge={ratedBackpackTopicsByChallenge}
      icon={TOPIC_IMPORTANCE_RATINGS[1].circleIcon}>
      <Typography paragraph>
        Here are topics that you said matter to you right now. If this is not true anymore, you can
        change your rating.
      </Typography>
    </MyTopicsDialog>
  );
};

export default BackpackDialog;
