import React, {useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import {useAuth} from '../Auth/AuthProvider';
import {useOnboarding} from '../Onboarding/OnboardingProvider';
import {
  MIN_YP_BIRTH_YEAR,
  MAX_YP_BIRTH_YEAR,
  MIN_TRANSITION_YEAR_YP_TOOL,
  MAX_TRANSITION_YEAR_YP_TOOL,
} from '../Util/constants';

import EditComponentWithSaveButton from '../Account/EditComponentWithSaveButton';
import YoungPersonDateInputPage from '../Onboarding/OnboardingPages/YoungPersonDateInputPage';
import LocalAuthorityPage from '../Onboarding/OnboardingPages/LocalAuthorityPage';
import TransitionTypePage from './Onboarding/TransitionTypePage';
import AboutYouIdentifiesAsDisbled from './AboutYouIndentifiesAsDisabled';
import AboutYouChallengeLevel from './AboutYouChallengeLevel';
import AdditionalSupportNeedsPage from '../Onboarding/OnboardingPages/AdditionalSupportNeedsPage';
import useYoungPersonTransitionTypes from '../Queries/useYoungPersonTransitionTypes';
import useYoungPersonProfileCopy from './useYoungPersonProfileCopy';

const nextYear = new Date().getFullYear() + 1;

export const DoB = () => {
  const {isLoading, youngPersonProfile, setYoungPersonProfile} = useYoungPersonProfileCopy();
  const {authState, updateYoungPersonProfile} = useAuth();

  if (isLoading) {
    return <CircularProgress />;
  } else {
    return (
      <EditComponentWithSaveButton
        buttonDisabled={
          !youngPersonProfile.birth_month ||
          !youngPersonProfile.birth_year ||
          (parseInt(youngPersonProfile.birth_month) === authState.user?.profile?.birth_month &&
            parseInt(youngPersonProfile.birth_year) === authState.user?.profile?.birth_year)
        }
        onClickSave={() =>
          updateYoungPersonProfile({
            id: youngPersonProfile.id,
            birth_month: youngPersonProfile.birth_month,
            birth_year: youngPersonProfile.birth_year,
          })
        }>
        <YoungPersonDateInputPage
          title="When were you born?"
          subtitle={`Compass is for young people of different ages. Your ratings and answers will show us what matters to people of your age.

      For example, if you were born in February 2007, you should enter 2 and 2007.`}
          month={youngPersonProfile.birth_month}
          setMonth={(month) => {
            setYoungPersonProfile({
              ...youngPersonProfile,
              birth_month: month,
            });
          }}
          year={youngPersonProfile.birth_year}
          setYear={(year) => {
            setYoungPersonProfile({...youngPersonProfile, birth_year: year});
          }}
          minYear={MIN_YP_BIRTH_YEAR}
          maxYear={MAX_YP_BIRTH_YEAR}
        />
      </EditComponentWithSaveButton>
    );
  }
};

export const LocalCouncil = () => {
  const {isLoading, youngPersonProfile, setYoungPersonProfile} = useYoungPersonProfileCopy();
  const {authState, updateYoungPersonProfile} = useAuth();
  const {onboardingState} = useOnboarding();
  const [livesInScotland, setLivesInScotland] = useState(
    !!authState.user?.profile?.local_authority_id
  );

  if (isLoading) {
    return <CircularProgress />;
  } else {
    return (
      <EditComponentWithSaveButton
        buttonDisabled={
          youngPersonProfile.local_authority_id === authState.user?.profile?.local_authority_id
        }
        onClickSave={() => {
          updateYoungPersonProfile({
            id: youngPersonProfile.id,
            local_authority_id: youngPersonProfile.local_authority_id,
          });
          setLivesInScotland(!!youngPersonProfile.local_authority_id); // hack to prevent stale authState
        }}>
        <LocalAuthorityPage
          livesInScotlandQuestion={'Do you live in Scotland?'}
          livesInScotlandCopy={
            'You can still use Compass, but some of the information we provide might not apply in your area.'
          }
          livesInScotland={livesInScotland}
          setLivesInScotland={setLivesInScotland}
          title="Select the council area you live in."
          subtitle="Compass asks this so that we know what is happening for young people in your area."
          localAuthority={
            onboardingState.localAuthorities.find(
              (auth) => auth.id === youngPersonProfile.local_authority_id
            )?.name
          }
          setLocalAuthority={(localAuthority) => {
            const local_authority_id =
              onboardingState.localAuthorities.find((auth) => auth.name === localAuthority)?.id ??
              null;
            setYoungPersonProfile({
              ...youngPersonProfile,
              local_authority_id,
            });
          }}
        />
      </EditComponentWithSaveButton>
    );
  }
};

export const BigChange = () => {
  const {isLoading, youngPersonProfile, setYoungPersonProfile} = useYoungPersonProfileCopy();
  const {authState, updateYoungPersonProfile} = useAuth();
  const {data: transitionTypes, isLoading: isLoadingTransitionTypes} =
    useYoungPersonTransitionTypes();
  if (isLoading || isLoadingTransitionTypes) {
    return <CircularProgress />;
  } else {
    return (
      <EditComponentWithSaveButton
        buttonDisabled={
          youngPersonProfile.transition_type.id === authState.user?.profile?.transition_type.id
        }
        onClickSave={() =>
          updateYoungPersonProfile({
            id: youngPersonProfile.id,
            young_person_transition_type_id: youngPersonProfile.young_person_transition_type_id,
          })
        }>
        <TransitionTypePage
          transitionType={youngPersonProfile.transition_type.name}
          setTransitionType={(transitionTypeName) => {
            const newTransitionType = transitionTypes?.find(
              (type) => type.name === transitionTypeName
            );
            setYoungPersonProfile({
              ...youngPersonProfile,
              transition_type: newTransitionType,
              young_person_transition_type_id: newTransitionType.id,
            });
          }}
        />
      </EditComponentWithSaveButton>
    );
  }
};

export const BigChangeDate = () => {
  const {isLoading, youngPersonProfile, setYoungPersonProfile} = useYoungPersonProfileCopy();
  const {authState, updateYoungPersonProfile} = useAuth();

  if (isLoading) {
    return <CircularProgress />;
  } else {
    return (
      <EditComponentWithSaveButton
        buttonDisabled={
          !youngPersonProfile.leaving_month ||
          !youngPersonProfile.leaving_year ||
          (parseInt(youngPersonProfile.leaving_month) === authState.user?.profile?.leaving_month &&
            parseInt(youngPersonProfile.leaving_year) === authState.user?.profile?.leaving_year)
        }
        onClickSave={() =>
          updateYoungPersonProfile({
            id: youngPersonProfile.id,
            leaving_month: youngPersonProfile.leaving_month,
            leaving_year: youngPersonProfile.leaving_year,
          })
        }>
        <YoungPersonDateInputPage
          title="When will this big change happen?"
          subtitle={`If you're not sure, it's OK. Just give us an estimate. This date will help Compass make sure you have all the information you need by the time you take your next big step.

      For example, if your big change will happen in February ${nextYear}, you should enter 2 and ${nextYear}.`}
          month={youngPersonProfile.leaving_month}
          setMonth={(month) =>
            setYoungPersonProfile({
              ...youngPersonProfile,
              leaving_month: month,
            })
          }
          year={youngPersonProfile.leaving_year}
          setYear={(year) =>
            setYoungPersonProfile({
              ...youngPersonProfile,
              leaving_year: year,
            })
          }
          minYear={MIN_TRANSITION_YEAR_YP_TOOL}
          maxYear={MAX_TRANSITION_YEAR_YP_TOOL}
          allowFutureOnly={true}
        />
      </EditComponentWithSaveButton>
    );
  }
};

export const Identify = () => {
  const {isLoading, youngPersonProfile, setYoungPersonProfile} = useYoungPersonProfileCopy();
  const {authState, updateYoungPersonProfile} = useAuth();

  if (isLoading) {
    return <CircularProgress />;
  } else {
    return (
      <EditComponentWithSaveButton
        buttonDisabled={
          youngPersonProfile.identifies_as_disabled ===
          authState.user?.profile?.identifies_as_disabled
        }
        onClickSave={() =>
          updateYoungPersonProfile({
            id: youngPersonProfile.id,
            identifies_as_disabled: youngPersonProfile.identifies_as_disabled,
          })
        }>
        <AboutYouIdentifiesAsDisbled
          identifiesAsDisabled={youngPersonProfile.identifies_as_disabled}
          onChange={(identifiesAsDisabled) =>
            setYoungPersonProfile({
              ...youngPersonProfile,
              identifies_as_disabled: identifiesAsDisabled,
            })
          }
        />
      </EditComponentWithSaveButton>
    );
  }
};

export const ChallengeLevel = () => {
  const {isLoading, youngPersonProfile, setYoungPersonProfile} = useYoungPersonProfileCopy();
  const {authState, updateYoungPersonProfile} = useAuth();

  if (isLoading) {
    return <CircularProgress />;
  } else {
    return (
      <EditComponentWithSaveButton
        buttonDisabled={
          youngPersonProfile.challenge_level === authState.user?.profile?.challenge_level
        }
        onClickSave={() =>
          updateYoungPersonProfile({
            id: youngPersonProfile.id,
            challenge_level: youngPersonProfile.challenge_level,
          })
        }>
        <AboutYouChallengeLevel
          challengeLevel={youngPersonProfile.challenge_level}
          onChange={(challengeLevel) =>
            setYoungPersonProfile({
              ...youngPersonProfile,
              challenge_level: challengeLevel,
            })
          }
        />
      </EditComponentWithSaveButton>
    );
  }
};

export const AdditionalSupportNeeds = () => {
  const {isLoading, youngPersonProfile, setYoungPersonProfile} = useYoungPersonProfileCopy();
  const {updateYoungPersonProfile} = useAuth();
  const {onboardingState} = useOnboarding();

  if (isLoading) {
    return <CircularProgress />;
  } else {
    return (
      <EditComponentWithSaveButton
        onClickSave={() =>
          updateYoungPersonProfile({
            id: youngPersonProfile.id,
            additional_support_need_categories:
              youngPersonProfile.additional_support_need_categories,
            custom_additional_support_need_categories:
              youngPersonProfile.custom_additional_support_need_categories,
          })
        }>
        {onboardingState.youngPersonAdditionalSupportNeedCategories ? (
          <AdditionalSupportNeedsPage
            heading="What is the nature of your additional support need or disability?"
            caption="Tick all boxes that apply to you."
            additionalSupportNeeds={onboardingState.youngPersonAdditionalSupportNeedCategories}
            selectedAdditionalSupportNeeds={youngPersonProfile.additional_support_need_categories}
            toggleAdditionalSupportNeed={(need, isChecked) => {
              if (isChecked) {
                setYoungPersonProfile({
                  ...youngPersonProfile,
                  additional_support_need_categories: [
                    ...youngPersonProfile.additional_support_need_categories,
                    need,
                  ],
                });
              } else {
                const newAdditionalSupportNeeds =
                  youngPersonProfile.additional_support_need_categories.filter(
                    (item) => item.id !== need.id
                  );
                setYoungPersonProfile({
                  ...youngPersonProfile,
                  additional_support_need_categories: newAdditionalSupportNeeds,
                });
              }
            }}
            customAdditionalSupportNeeds={
              youngPersonProfile.custom_additional_support_need_categories
            }
            setCustomAdditionalSupportNeeds={(newCustomAdditionalSupportNeedNames) => {
              setYoungPersonProfile({
                ...youngPersonProfile,
                custom_additional_support_need_categories: newCustomAdditionalSupportNeedNames,
              });
            }}
            otherLabel="Other"
          />
        ) : (
          <div>Loading...</div>
        )}
      </EditComponentWithSaveButton>
    );
  }
};
