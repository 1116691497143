import React, {useState} from 'react';

import ViewPreviousOrNextCategoryButtons from './ViewPreviousOrNextCategoryButtons';
import CategoryCarouselButtons from './CategoryCarouselButtons';
import YoungPersonCategoryTile from './YoungPersonCategoryTile';

const MinimalCategoryListView = ({categories, onSelectCategory}) => {
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const currentCategory = categories[currentCategoryIndex];

  return (
    <>
      <ViewPreviousOrNextCategoryButtons
        onPreviousClick={() => {
          if (currentCategoryIndex > 0) {
            setCurrentCategoryIndex(currentCategoryIndex - 1);
          }
        }}
        onNextClick={() => {
          if (currentCategoryIndex < categories?.length - 1) {
            setCurrentCategoryIndex(currentCategoryIndex + 1);
          }
        }}
        previousButtonDisabled={currentCategoryIndex === 0}
        nextButtonDisabled={currentCategoryIndex === categories?.length - 1}
      />
      {currentCategory && (
        <YoungPersonCategoryTile
          key={currentCategory.id}
          category={currentCategory}
          onSelectCategory={onSelectCategory}
          viewMode="minimal"
        />
      )}
      <CategoryCarouselButtons
        categoryArray={categories}
        currentIndex={currentCategoryIndex}
        setCurrentIndex={setCurrentCategoryIndex}
      />
    </>
  );
};

export default MinimalCategoryListView;
