import React from 'react';

const LinearProgressIndicator = ({percentageProgress, outerColor, innerColor}) => {
  return (
    <div className="linear-progress-indicator-outer" style={{backgroundColor: outerColor}}>
      <div
        className="linear-progress-indicator-inner"
        style={{width: `${percentageProgress}%`, backgroundColor: innerColor}}
      />
    </div>
  );
};

export default LinearProgressIndicator;
