import React from 'react';
import {useTheme} from '@mui/material/styles';

import CompletedEvaluation from '../Evaluation/CompletedEvaluation';
import PageHeader from '../Util/PageHeader';
import feedbackIcon from '../img/EvaluationLight.svg';
import {CONTACT_EMAIL_ADDRESS, FEEDBACK_STRINGS} from '../Util/constants';
import {Link, Typography} from '@mui/material';

const YoungPersonCompletedEvaluationScreen = () => {
  const theme = useTheme();

  return (
    <CompletedEvaluation
      pageHeader={
        <PageHeader
          color={theme.palette.pink}
          name={FEEDBACK_STRINGS.youngPerson.feedbackTitle}
          icon={feedbackIcon}
          backTo="/feedback"
        />
      }
      content={
        <>
          <Typography variant="h2" gutterBottom>
            Thank you for answering these questions.
          </Typography>
          <Typography paragraph>
            Your answers will be added to the answers from other young people, and will be used to
            help make things better for young people in Scotland.
          </Typography>
          <Typography paragraph>No one will be able to tell it was you who answered.</Typography>
          <Typography paragraph>
            If you have any problems using Compass, you can email us at{' '}
            <Link href={`mailto:${CONTACT_EMAIL_ADDRESS}`} target="_blank" rel="noreferrer">
              {CONTACT_EMAIL_ADDRESS}
            </Link>
            .
          </Typography>
          <Typography paragraph>
            Remember, you can see answers you have given before by going to '
            {FEEDBACK_STRINGS.youngPerson.feedbackHistoryTitle}'.
          </Typography>
        </>
      }
    />
  );
};

export default YoungPersonCompletedEvaluationScreen;
