import React from 'react';
import {useTheme} from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import {Container, Link, Typography} from '@mui/material';

import PageHeader from '../Util/PageHeader';
import PrivacyHeaderIcon from '../img/privacy-header.svg';
import {YP_HELPLINE_NUMBERS} from '../Util/constants';

const YoungPersonCrisisHelp = () => {
  const theme = useTheme();

  const {dark, contrastText} = theme.palette.primary;

  return (
    <>
      <PageHeader
        color={dark}
        name={'I need someone to talk to'}
        icon={PrivacyHeaderIcon} // TODO: replace icon
        textColor={contrastText}
      />
      <Container maxWidth="md" sx={{py: 4}}>
        <Typography paragraph>
          Remember, you can speak to someone you trust, like a teacher or a parent.
        </Typography>
        <Typography variant="h4" gutterBottom>
          If you are under 19:
        </Typography>
        <ul>
          <li>
            Call Childline using{' '}
            <Link className="bold" href={`tel:${YP_HELPLINE_NUMBERS.childline}`}>
              {YP_HELPLINE_NUMBERS.childline}
            </Link>{' '}
            (This call is free, and the number won't appear on your phone bill).
          </li>
        </ul>
        <Typography variant="h4" gutterBottom>
          If you are 19 or over:
        </Typography>
        <ul>
          <li>
            Call The Mix using{' '}
            <Link className="bold" href={`tel:${YP_HELPLINE_NUMBERS.theMix}`}>
              {YP_HELPLINE_NUMBERS.theMix}
            </Link>{' '}
            (This call is free)
          </li>
        </ul>
        <Typography variant="h4" gutterBottom>
          If it's easier for you to <span className="bold">text</span>:
        </Typography>
        <ul>
          <li>
            Text <span className="bold">SHOUT</span> to{' '}
            <Link className="bold" href={`sms:${YP_HELPLINE_NUMBERS.shout}`}>
              {YP_HELPLINE_NUMBERS.shout}
            </Link>
            .
          </li>
        </ul>
        <Divider aria-hidden="true" sx={{my: 2}} />
        <Typography variant="h4" gutterBottom>
          If you need medical help urgently:
        </Typography>
        <ul>
          <li>
            Contact{' '}
            <Link className="bold" href={`tel:${YP_HELPLINE_NUMBERS.nhsEmergency}`}>
              {YP_HELPLINE_NUMBERS.nhsEmergency}
            </Link>{' '}
            and ask for an ambulance.
          </li>
        </ul>
      </Container>
    </>
  );
};

export default YoungPersonCrisisHelp;
