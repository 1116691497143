import React from 'react';
import {Typography} from '@mui/material';

import {useYoungPersonTransition} from './YoungPersonTransitionProvider';
import MyTopicsDialog from './MyTopicsDialog';
import {TOPIC_IMPORTANCE_RATINGS} from '../Util/constants';

const UnsureTopicsDialog = () => {
  const {ratedUnsureTopicsByChallenge} = useYoungPersonTransition();
  return (
    <MyTopicsDialog
      title="Topics you weren't sure about"
      ratedTopicsByChallenge={ratedUnsureTopicsByChallenge}
      icon={TOPIC_IMPORTANCE_RATINGS[2].circleIcon}>
      <Typography paragraph>
        Here are topics that you were not sure about right now right now. If this is not true
        anymore, you can change your rating.
      </Typography>
    </MyTopicsDialog>
  );
};

export default UnsureTopicsDialog;
