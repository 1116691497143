import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Container, Paper, Typography} from '@mui/material';

import {useYoungPersonTransition} from './YoungPersonTransitionProvider';
import {compassArrowIcons, YOUNG_PERSON_CATEGORY_ICONS, sectionIcons} from '../Util/constants';
import PageHeader from '../Util/PageHeader';
import TransitionTopicTile from './TransitionTopicTile';

const YoungPersonTransitionCategoryTopicsList = () => {
  const {categoryId} = useParams();
  const theme = useTheme();
  const {categories} = useYoungPersonTransition();
  const navigate = useNavigate();

  const [ratedTopicsAreVisible, setRatedTopicsAreVisible] = useState(true);
  const [ratedUnsureTopicsAreVisible, setRatedUnsureTopicsAreVisible] = useState(true);
  const [unratedTopicsAreVisible, setUnratedTopicsAreVisible] = useState(true);

  const currentCategory = categories?.find((category) => category.id === parseInt(categoryId));

  const ratedUnsureTopics = currentCategory?.topics.filter(
    (topic) => topic.rating?.importance_rating === 2
  );
  const ratedTopics = currentCategory?.topics.filter(
    (topic) => (topic.rating?.importance_rating === 1) | (topic.rating?.importance_rating === 3)
  );
  const unratedTopics = currentCategory?.topics.filter((topic) => !topic.rating);

  const isLoading = !ratedUnsureTopics || !ratedTopics || !unratedTopics;

  return (
    <>
      <PageHeader
        name={'Topics'}
        color={theme.palette.primary.light}
        icon={sectionIcons.transitionTopicsIcon}
        backTo="/categories"
      />
      <Container maxWidth="md" sx={{py: 4}}>
        {currentCategory && (
          <>
            <Paper sx={{borderRadius: 5, mb: 2, p: 2, textAlign: 'center'}}>
              <img
                src={YOUNG_PERSON_CATEGORY_ICONS[currentCategory.key]}
                width={150}
                height={150}
              />
              <Typography variant="h2" component="h1" gutterBottom>
                {currentCategory.name}
              </Typography>
              <Typography paragraph variant="body2">
                {currentCategory.description}
              </Typography>
            </Paper>

            {unratedTopics.length > 0 &&
              (ratedTopics.length > 0 || ratedUnsureTopics.length > 0) && (
                <>
                  <button
                    className="button-reset topic-card-header"
                    onClick={() => setUnratedTopicsAreVisible(!unratedTopicsAreVisible)}>
                    <p className="body1 semibold">Topics still to explore</p>
                    {unratedTopicsAreVisible ? (
                      <img src={compassArrowIcons.collapseIcon.mono} />
                    ) : (
                      <img src={compassArrowIcons.expandIcon.mono} />
                    )}
                  </button>
                </>
              )}
            <div className="topics-container">
              {unratedTopicsAreVisible &&
                unratedTopics.map((topic) => (
                  <TransitionTopicTile
                    key={topic.id}
                    onClick={() => navigate(`/categories/${categoryId}/topics/${topic.id}/rate`)}
                    topic={topic}
                    onClickLabel="Rate topic"
                  />
                ))}
            </div>

            {ratedUnsureTopics.length > 0 &&
              (unratedTopics.length > 0 || ratedUnsureTopics.length > 0) && (
                <>
                  <button
                    className="button-reset topic-card-header"
                    onClick={() => setRatedUnsureTopicsAreVisible(!ratedUnsureTopicsAreVisible)}>
                    <p className="body1 semibold">Topics you were not sure about</p>
                    {ratedUnsureTopicsAreVisible ? (
                      <img src={compassArrowIcons.collapseIcon.mono} />
                    ) : (
                      <img src={compassArrowIcons.expandIcon.mono} />
                    )}
                  </button>
                </>
              )}
            <div className="topics-container">
              {ratedUnsureTopicsAreVisible &&
                ratedUnsureTopics.map((topic) => (
                  <TransitionTopicTile
                    key={topic.id}
                    onClick={() => navigate(`/categories/${categoryId}/topics/${topic.id}`)}
                    topic={topic}
                    onClickLabel="View topic"
                  />
                ))}
            </div>

            {ratedTopics.length > 0 &&
              (unratedTopics.length > 0 || ratedUnsureTopics.length > 0) && (
                <>
                  <button
                    className="button-reset topic-card-header"
                    onClick={() => setRatedTopicsAreVisible(!ratedTopicsAreVisible)}>
                    <p className="body1 semibold">Topics you have rated</p>
                    {ratedTopicsAreVisible ? (
                      <img src={compassArrowIcons.collapseIcon.mono} />
                    ) : (
                      <img src={compassArrowIcons.expandIcon.mono} />
                    )}
                  </button>
                </>
              )}
            <div className="topics-container">
              {ratedTopicsAreVisible &&
                ratedTopics.map((topic) => (
                  <TransitionTopicTile
                    key={topic.id}
                    onClick={() => navigate(`/categories/${categoryId}/topics/${topic.id}`)}
                    topic={topic}
                    onClickLabel="View topic"
                  />
                ))}
            </div>
          </>
        )}
        {isLoading && <p>Loading...</p>}
      </Container>
    </>
  );
};

export default YoungPersonTransitionCategoryTopicsList;
