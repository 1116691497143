import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Container} from '@mui/material';

import {useEvaluation} from './EvaluationProvider';

const Evaluation = ({pageHeader, EvaluationTheme}) => {
  const navigate = useNavigate();
  const {evaluationState, isLoading, fetchQuestions} = useEvaluation();

  const hasQuestions = !!evaluationState.questionsByThemeName;
  const currentEvaluationTheme = hasQuestions
    ? evaluationState.questionsByThemeName[evaluationState.currentThemeIndex]
    : undefined;

  useEffect(() => {
    // This useEffect makes sure we re-fetch questions if user reloads the page
    if (!hasQuestions) {
      fetchQuestions();
    } else {
      // if all themes have been evaluated, navigate to comments page
      if (evaluationState.currentThemeIndex >= evaluationState.questionsByThemeName.length) {
        navigate('/feedback/comments');
      }
    }
  }, [hasQuestions, evaluationState.questionsByThemeName, evaluationState.currentThemeIndex]);

  return (
    <>
      {pageHeader}
      <Container maxWidth="md" sx={{pb: 4}}>
        {isLoading || !hasQuestions ? (
          <p>Loading...</p>
        ) : (
          hasQuestions &&
          currentEvaluationTheme && <EvaluationTheme evaluationTheme={currentEvaluationTheme} />
        )}
      </Container>
    </>
  );
};

export default Evaluation;
