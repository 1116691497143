import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import Divider from '@mui/material/Divider';

import {useYoungPersonTransition} from './YoungPersonTransitionProvider';
import {sectionIcons, CATEGORY_VIEW_MODES} from '../Util/constants';
import PageHeader from '../Util/PageHeader';
import AllCategoriesListView from './AllCategoriesListView';
import MinimalCategoryListView from './MinimalCategoryListView';
import MediumCategoryListView from './MediumCategoryListView';
import {Container, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';
import {Box} from '@mui/system';

const CategoryListViewOptions = ({viewMode, setViewMode}) => {
  return (
    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
      <div>
        <div className="semibold" style={{marginBottom: 6, textAlign: 'end'}}>
          View Options
        </div>
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={(_, value) => {
            if (value !== null) {
              setViewMode(value);
              window.localStorage.setItem('category-view-mode', value);
            }
          }}
          size="small">
          {CATEGORY_VIEW_MODES.map((mode) => (
            <ToggleButton
              key={mode.value}
              value={mode.value}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                textTransform: 'none',
              }}>
              <img src={mode.icon} />
              <div className={viewMode === mode.value ? 'semibold' : ''}>{mode.label}</div>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </Box>
  );
};

const YoungPersonTransitionCategoryList = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {categories} = useYoungPersonTransition();
  const [viewMode, setViewMode] = useState(
    window.localStorage.getItem('category-view-mode') ?? 'all'
  );
  const onSelectCategory = (category) => navigate(`/categories/${category.id}`);
  return (
    <>
      <PageHeader
        name={'Categories'}
        color={theme.palette.primary.light}
        icon={sectionIcons.transitionTopicCategoryIcons}
      />
      <Container maxWidth="md" sx={{py: 4}}>
        <Typography paragraph>
          When you go through big changes in your life you might have to make choices and decisions.
          It can be helpful to work out what is most important to you.
        </Typography>
        <Typography paragraph>
          Compass will help you work out what you think is important and what is hard to understand
          right now.
        </Typography>
        <Divider aria-hidden="true" sx={{my: 2}} />
        <CategoryListViewOptions viewMode={viewMode} setViewMode={setViewMode} />
      </Container>
      <Box sx={{mb: 6}}>
        {viewMode === 'all' ? (
          <Container maxWidth="md">
            <AllCategoriesListView categories={categories} onSelectCategory={onSelectCategory} />
          </Container>
        ) : viewMode === 'medium' ? (
          <MediumCategoryListView categories={categories} onSelectCategory={onSelectCategory} />
        ) : (
          viewMode === 'minimal' && (
            <MinimalCategoryListView categories={categories} onSelectCategory={onSelectCategory} />
          )
        )}
      </Box>
    </>
  );
};

export default YoungPersonTransitionCategoryList;
