import React from 'react';
import {useTheme} from '@mui/material/styles';

import {useAuth} from '../Auth/AuthProvider';
import LinearProgressIndicator from '../Util/LinearProgressIndicator';
import {monthAndYearDateFormatter} from '../Util/constants';
import flagIcon from '../img/flag.svg';
import marker from '../img/yp-triangle.svg';
import hikingPersonIcon from '../img/hiking-person.svg';
import {Box, Typography} from '@mui/material';

const YoungPersonTransitionProgressIndicator = () => {
  const theme = useTheme();
  const {authState} = useAuth();
  const transitionTypeTitle = authState.user.profile.transition_type.name;
  const {percentage_to_transition} = authState.user.profile;

  const signUpDate = new Date(authState?.user?.profile?.created_at);
  const leavingDate = new Date(
    `${authState?.user?.profile?.leaving_month}/1/${authState?.user?.profile?.leaving_year}`
  );

  const signUpDateString = monthAndYearDateFormatter.format(signUpDate);
  const leavingDateString = monthAndYearDateFormatter.format(leavingDate);

  return (
    <Box
      id="stage-details"
      sx={{
        padding: 2,
        [theme.breakpoints.up('md')]: {padding: 3},
        backgroundColor: theme.palette.background.default,
        mb: 2,
        borderRadius: 5,
      }}>
      <Typography variant="h3" gutterBottom>
        {transitionTypeTitle}
      </Typography>
      <div className="progress-indicator-row">
        <img
          src={hikingPersonIcon}
          className="progress-indicator-left-icon"
          width={48}
          height={48}
        />
        <div className="progress-indicator-column">
          <LinearProgressIndicator
            percentageProgress={percentage_to_transition}
            outerColor={'white'}
            innerColor={theme.palette.secondary.main}
          />
          <div className="progress-indicator-markers-row">
            <div className="progress-indicator-marker-container left-aligned">
              <img
                aria-hidden="true"
                className="progress-indicator-marker"
                src={marker}
                height={5}
              />
              <img
                src={hikingPersonIcon}
                width={25}
                height={25}
                className="progress-indicator-left-icon-mobile"
              />
              <div className="caption">Signed up: {signUpDateString}</div>
            </div>
            <div className="progress-indicator-marker-container centre-aligned">
              <img
                aria-hidden="true"
                className="progress-indicator-marker"
                src={marker}
                height={5}
              />
              <div className="caption">Halfway</div>
            </div>
            <div className="progress-indicator-marker-container right-aligned">
              <img
                aria-hidden="true"
                className="progress-indicator-marker"
                src={marker}
                height={5}
              />
              <img
                aria-hidden="true"
                src={flagIcon}
                width={25}
                height={25}
                className="progress-indicator-right-icon-mobile"
              />
              <div className="caption">Next step: {leavingDateString}</div>
            </div>
          </div>
        </div>
        <img
          aria-hidden="true"
          src={flagIcon}
          width={48}
          height={48}
          className="progress-indicator-right-icon"
        />
      </div>
    </Box>
  );
};
export default YoungPersonTransitionProgressIndicator;
