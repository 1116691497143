import React from 'react';
import Dialog from '@mui/material/Dialog';
import {Button, Typography} from '@mui/material';

import {onCloseUnlessBackdropClick} from '../Util/helpers';
import ratingTopicsIllustration from '../img/yp-rating.svg';

const HowToRateTopicsDialog = ({isVisible, closeDialog}) => {
  return (
    <Dialog
      open={isVisible}
      onClose={(event, reason) => onCloseUnlessBackdropClick(event, reason, closeDialog)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <div className="alert-dialog-container">
        <Typography variant="h2" id="alert-dialog-title">
          Rating Topics
        </Typography>
        <img src={ratingTopicsIllustration} className="screen-illustration" alt="" />
        <div id="alert-dialog-description">
          <Typography paragraph variant="body2">
            Compass makes it easy to understand what topics matter to you. It also helps you work
            out what topics you need to learn more about.
          </Typography>
          <Typography paragraph variant="body2">
            You can work this out by rating how much a topic matters to you, and then how well you
            understand it.
          </Typography>
          <Typography paragraph variant="body2">
            You can change your mind about how you would like to rate a topic at any time.
          </Typography>
        </div>
        <Button variant="contained" color="secondary" onClick={closeDialog} autoFocus>
          Continue
        </Button>
      </div>
    </Dialog>
  );
};

export default HowToRateTopicsDialog;
