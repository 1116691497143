import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import '../InitSentry';
import {MatomoAnalyticsProvider} from '../MatomoAnalytics';
import DarkModeThemeProvider from '../DarkModeThemeProvider';
import {AuthProvider} from '../Auth/AuthProvider';
import {ViewportProvider} from '../ViewportProvider';
import App from './App';

// Load our global styles
import './colors.css';
import '../css/variables.css';
import '../css/typography.css';
import '../css/index.css';
import '../css/layout.css';
import '../css/young-person/layout.css';
import '../css/UIelements.css';
import '../css/young-person/UIelements.css';
import '../css/buttons.css';
import '../css/card.css';
import '../css/auth.css';
import '../css/onboarding.css';
import '../css/home.css';
import '../css/young-person/home.css';
import '../css/evaluation.css';
import '../css/young-person/evaluation.css';
import '../css/young-person/transition-categories.css';
import '../css/young-person/topic-dialogs.css';

import getCustomTheme from './CustomTheme';

const container = document.getElementById('arc-yp-app');
const root = createRoot(container);

root.render(
  <MatomoAnalyticsProvider>
    <DarkModeThemeProvider getCustomTheme={getCustomTheme}>
      <BrowserRouter>
        <AuthProvider>
          <ViewportProvider>
            <App />
          </ViewportProvider>
        </AuthProvider>
      </BrowserRouter>
    </DarkModeThemeProvider>
  </MatomoAnalyticsProvider>
);
