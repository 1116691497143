import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Container} from '@mui/system';
import {Typography} from '@mui/material';

import {useEvaluation} from './EvaluationProvider';
import {longDateFormatter} from '../Util/constants';
import PreviousEvaluationRow from '../Evaluation/PreviousEvaluationRow';

const EvaluationHistory = ({pageHeader, content, filter}) => {
  const {evaluationState, fetchHistory} = useEvaluation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvaluationHistory = async () => await fetchHistory();
    fetchEvaluationHistory();
  }, []);

  return (
    <>
      {pageHeader}
      <Container maxWidth="md" sx={{paddingTop: {xs: 2, md: 4}}}>
        <Typography variant="h2" gutterBottom>
          Previous Responses
        </Typography>
        {content}
        {evaluationState.evaluations !== undefined ? (
          // The `filter` prop will (optionally) filter evaluations e.g by young_person_id.
          // If not set, all evaluations will be shown
          evaluationState.evaluations.filter(filter ?? (() => true)).map((evaluation) => {
            const evaluationDate = longDateFormatter.format(new Date(evaluation.completed_at));
            const onClick = evaluation.completed_at
              ? () => navigate(`/feedback/history/${evaluation.id}`)
              : () => navigate('/feedback/new');
            const title = evaluation.completed_at
              ? `What you said on ${evaluationDate}`
              : 'In progress...';

            return (
              <PreviousEvaluationRow
                key={`evaluation-${evaluation.id}`}
                onClick={onClick}
                title={title}
                stage={evaluation.stage}
              />
            );
          })
        ) : (
          <p>Loading...</p>
        )}
      </Container>
    </>
  );
};

export default EvaluationHistory;
