import React from 'react';

import Divider from '@mui/material/Divider';
import {Card, CardActionArea} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const HomeScreenCard = ({
  id,
  onClick,
  disabled = false,
  headerBackgroundColor,
  headerIconSrc,
  title,
  description,
  actionRow,
  ...props
}) => {
  return (
    <Card sx={{borderRadius: 5, mb: 2, cursor: disabled ? 'not-allowed' : 'pointer'}} id={id}>
      <CardActionArea onClick={onClick} disabled={disabled} {...props}>
        <div className="card-header" style={{backgroundColor: headerBackgroundColor}}>
          <img src={headerIconSrc} height={80} />
        </div>
        {title || description ? (
          <div className="left-aligned card-contents">
            {title ? <div className="header-4">{title}</div> : null}
            {typeof description === 'string' ? (
              <div className="body2">{description}</div>
            ) : (
              <>{description}</>
            )}
          </div>
        ) : null}
        {actionRow && (
          <div className="card-footer left-aligned">
            {title || description ? <Divider aria-hidden="true" /> : null}
            <div className="card-footer-row">
              {actionRow}
              <ArrowForwardIcon />
            </div>
          </div>
        )}
      </CardActionArea>
    </Card>
  );
};

export default HomeScreenCard;
