import React from 'react';
import {compassArrowIcons} from '../Util/constants';
import {Typography} from '@mui/material';

const DialogSectionHeaderButton = ({onClick, colour, icon, title, sectionContentsAreVisible}) => {
  return (
    <button
      className="button-reset topic-dialog-section"
      style={{backgroundColor: colour}}
      onClick={onClick}>
      <div className="header-with-icon">
        <img src={icon} height={24} width={24} alt="" />
        <Typography variant="h5" color="black">
          {title}
        </Typography>
      </div>
      <img
        src={
          sectionContentsAreVisible
            ? compassArrowIcons.collapseIcon.mono
            : compassArrowIcons.expandIcon.mono
        }
        alt=""
      />
    </button>
  );
};

export default DialogSectionHeaderButton;
