import React from 'react';
import {useTheme} from '@mui/material/styles';
import {HashLink} from 'react-router-hash-link';
import {Container, Link, Typography} from '@mui/material';

import PageHeader from '../Util/PageHeader';
import PrivacyHeaderIcon from '../img/privacy-header.svg';
import {CONTACT_EMAIL_ADDRESS} from '../Util/constants';
import ProcessingType from '../ProcessingType';
import PrivacyRight from '../PrivacyRight';

const YoungPersonPrivacyNotice = () => {
  const theme = useTheme();
  const {dark, contrastText} = theme.palette.primary;
  return (
    <>
      <PageHeader
        color={dark}
        name={'Privacy Notice'}
        icon={PrivacyHeaderIcon}
        textColor={contrastText}
      />
      <Container maxWidth="md" sx={{py: 6}}>
        <div className="home-section">
          <Typography variant="h2" gutterBottom>
            In brief: what happens to the information that you put into Compass
          </Typography>
          <ul>
            <li>
              Compass asks for information about you and your feelings about moving to further
              education, a job or adult care
            </li>
            <li>
              This aim is to help make this experience better for you and others in the future and
              also to provide you with relevant information to help you
            </li>
            <li>
              Some of the information you give us will be shared with relevant Local Authorities but
              nothing that identifies you will be linked to that information so you cannot be
              identified by your Local Authority as the person who gave that feedback
            </li>
            <li>
              We want to reassure you about how your information will be looked after in Compass and
              about what you can do if you are worried or have questions about how it will be looked
              after
            </li>
          </ul>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom>
            In more detail: what happens to the information you put into Compass
          </Typography>
          <ul>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#app'}}>
                Who has created Compass and what is it for?
              </Link>
            </li>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#contact'}}>
                ARC contact details
              </Link>
            </li>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#types-of-info'}}>
                The types of personal information we collect and what we do with it
              </Link>
            </li>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#how-we-get'}}>
                A wee bit about how we get the information about you
              </Link>
            </li>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#legal'}}>
                The Legal Stuff
              </Link>
            </li>
            <li>
              <Link
                component={HashLink}
                smooth
                to={{pathname: '/privacy', hash: '#do-not-provide'}}>
                What happens if you do not want to provide us with the information we ask for?
              </Link>
            </li>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#look-after'}}>
                How we look after the information you provide
              </Link>
            </li>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#how-long'}}>
                How long will we keep your information?
              </Link>
            </li>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#rights'}}>
                Your data protection rights
              </Link>
            </li>
            <li>
              <Link component={HashLink} smooth to={{pathname: '/privacy', hash: '#queries'}}>
                Queries or concerns
              </Link>
            </li>
          </ul>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="app">
            Who has created Compass and what is it for?
          </Typography>
          <Typography paragraph>
            Compass was created and is operated by ARC in Scotland. ARC Scotland set up the
            Transition Forum and has been working with the Scottish Government, Local Authorities
            and other organisations to improve the experience of children and young adults (14 to 25
            years) with additional support needs as they make the transition to young adult life.
            More information about this project can be found{' '}
            <Link
              href="https://scottishtransitions.org.uk/7-principles-of-good-transitions/"
              target="_blank"
              rel="noreferrer">
              here
            </Link>
            .
          </Typography>
          <Typography paragraph>Compass is part of this project and has two aims:</Typography>
          <ul>
            <li>
              To provide you with relevant information through your transition from a child to an
              adult; and
            </li>
            <li>
              To share the insights you provide about your experience to help improve the transition
              experience.
            </li>
          </ul>
          <Typography paragraph>
            When you use Compass we ask you to answer various questions about your transition
            journey; and how that experience felt. We can then provide you with information that is
            relevant to what is happening to you when you use Compass.
          </Typography>
          <Typography paragraph>
            Some of the information will be shared with relevant local authorities. But it is
            important to understand that none of the information that is shared will be linked to
            you. It is shared along with the information provided by other young people but your
            name is not shared.
          </Typography>
          <Typography paragraph>
            We will publish reports from time to time which will contain information obtained
            through Compass but which will never identify any individual. These reports will be
            publicly available with the same aim of improving the transition process.
          </Typography>
          <Typography paragraph>
            This notice is here to give you more detail about what we are doing with your personal
            data – information about you. We want to tell you how we are keeping it safe and about
            the rights that you have in relation to your information. We want you to feel
            comfortable that the personal information you are giving to us will be looked after.
          </Typography>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="contact">
            ARC contact details
          </Typography>
          <Typography paragraph>Name: Association for Real Change</Typography>
          <Typography paragraph>
            Address: ARC House, Marden Street, Chesterfield, Derbyshire S40 1JY
          </Typography>
          <Typography paragraph>
            Email:
            <Link href={`mailto:${CONTACT_EMAIL_ADDRESS}`} target="_blank" rel="noreferrer">
              {CONTACT_EMAIL_ADDRESS}
            </Link>
          </Typography>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="types-of-info">
            The types of personal information we collect and what we do with it
          </Typography>
          <Typography paragraph>
            We collect and use the following information through Compass:
          </Typography>
          <ProcessingType
            name="Account Information"
            purpose="When you use Compass, you create a profile which includes your username and email
            address. This simply allows you to access your profile and to use Compass.We will send
            you password reminders or other essential account information by email. We will send you
            reminders by email or SMS if you ask us to."
            shared="Never"
          />
          <ProcessingType
            name="Your Profile"
            purpose="We will ask you questions about you to provide you with the information that will help
            you. This will include information about you and why you need the support that you need.
            We know that this may be information that you feel sensitive and more private about and
            so we want to reassure you that we do need this information to provide you with relevant
            advice and to help to improve services for young people like you. We will look after
            this type of information very carefully and sensitively. It will be kept in Compass
            securely."
            shared={
              <>
                <Typography paragraph>
                  Some of this information will be shared but not in a way that it can be linked to
                  you. This includes:
                </Typography>
                <ul>
                  <li>the local authority area or areas that you live in or use.</li>
                  <li>the particular needs you have.</li>
                </ul>
              </>
            }
          />
          <ProcessingType
            name="Transition Information"
            purpose="We will ask you questions about the experience that you have had when you move from
            being a child to being an adult: what you have found easy and what you have found hard."
            shared="This will be shared but not in a way that it can be linked to you."
          />
          <ProcessingType
            name="Evaluation Information"
            purpose="We will ask you to say how good or bad your experience through transition was."
            shared="This will be shared but not in a way that it can be linked to you."
          />
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="how-we-get">
            A wee bit about how we get the information about you
          </Typography>
          <Typography paragraph>
            The simple answer to that is: from{' '}
            <span style={{textDecoration: 'underline'}}>you</span>. We do not gather any more
            information about you from other places. It is only the information that you give to us
            that we use.
          </Typography>
          <Typography paragraph>
            You may have heard about something called Cookies. These allow websites and Apps to
            function better but can also be used to gather a lot of information about how you behave
            online. We only use Cookies which are absolutely necessary to allow Compass to function
            and recognise you and so none of the information gathering type.
          </Typography>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="legal">
            The Legal Stuff
          </Typography>
          <Typography paragraph>
            Under a law called the UK General Data Protection Regulation (GDPR), we need to have a
            good legal reason to use your information. We have set these out below for those
            interested in the legal stuff.
          </Typography>

          <Typography variant="h3" gutterBottom>
            Contractual Obligation
          </Typography>
          <Typography paragraph>
            We use Account Information (the username and email address you give us) so that we can
            create an account and we can give you the information that you need and we can gather
            the information that we need. This allows you to access your account.
          </Typography>

          <Typography variant="h3" gutterBottom>
            Legitimate Interest
          </Typography>
          <Typography paragraph>
            We are storing the answers you provide to our questions about your experience so that we
            can work with other organisations to improve the journey for young people like you
            moving from childhood to adulthood.
          </Typography>
          <Typography paragraph>
            ARC Transition Forum is trying to make this better and we believe that is a good reason
            for us to ask personal questions but we have considered your feelings about how this
            information is used and that is why any information that we share cannot be linked to
            you as an individual.
          </Typography>
          <Typography paragraph>
            We will only share information about groups of young people without their names to make
            sure you cannot be identified.
          </Typography>

          <Typography variant="h3" gutterBottom>
            Substantial Public Interest
          </Typography>
          <Typography paragraph>
            We are asking for some information about you which may include sensitive information
            about your health. The law allows us to ask for this information as we are a not for
            profit body supporting young people with special needs.
          </Typography>
          <Typography paragraph>
            Again when the information is shared it is about a group of young people and does not
            include their names.
          </Typography>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="do-not-provide">
            What happens if you do not want to provide us with the information we ask for?
          </Typography>
          <Typography paragraph>
            We hope that the information we have given you in this notice reassures you about why we
            are asking for your information and what we are going to do with it. If you decide not
            to give us certain information that is absolutely fine but it is likely that Compass
            will not work very well for you and we may be unable to provide you with relevant
            information about your stage.
          </Typography>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="look-after">
            How we look after the information you provide
          </Typography>
          <Typography paragraph>
            We work closely with a technology company called Wheelhouse who helped us to develop
            Compass and who keep it working. They also store the information gathered in Compass on
            their database on our behalf.
          </Typography>
          <Typography paragraph>
            Wheelhouse are legally called a processor which means that they provide us with the
            services we asked them to, under a legal contract we have with them. Wheelhouse cannot
            do anything with your information unless we tell them to. So they will not share your
            information with any organisation, apart from ARC, unless we tell them they can.
          </Typography>
          <Typography paragraph>
            They will be looking after the information securely and only for as long as we tell them
            to.
          </Typography>
          <Typography paragraph>
            Wheelhouse work with organisations like ARC to create websites and Apps which are
            designed to work for everyone, including disabled and neurodiverse people. That’s why we
            like working with them.
          </Typography>
          <Typography paragraph>
            Wheelhouse uses a database run by Amazon Web Services and which is in the UK and they
            use other providers to send emails and SMS if you have requested reminders. They use
            cyber security expert advice to keep everything as secure as possible.
          </Typography>
          <Typography paragraph>
            So, for technical people, the data gathered by Compass is encrypted when being
            transferred between your device and the database. Encryption is a process which keeps
            the information secret involving a mathematical formula which means that only those with
            authorisation can understand it, because they have the ‘key’.
          </Typography>
          <Typography paragraph>The data is also encrypted when it is stored.</Typography>
          <Typography paragraph>
            Before information is shared with relevant local authorities, it is separated from your
            personal details, combined with other information and only this information is shared.
            It becomes what we call “aggregated and anonymised”: grouped together with no names!
          </Typography>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="how-long">
            How long will we keep your information?
          </Typography>
          <Typography paragraph>
            We will keep your account open until you tell us to close it. Or if we do not hear from
            you when you reach the age of 25 we will contact you and let you know that your account
            information (email address and name) will be securely deleted from our database.
          </Typography>
          <Typography paragraph>
            We will retain the other information that you provided to us, but because we deleted
            your name and contact details it cannot be linked back to you. However it will continue
            to help us improve the transition journey for young people in Scotland.
          </Typography>
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="rights">
            Your data protection rights
          </Typography>
          <Typography paragraph>
            Under the law, you have rights which are listed here and below we will tell you how you
            can ask us about them in relation to the information in Compass. We want you to be clear
            about what is happening to your information and we are happy to answer any of your
            questions.
          </Typography>
          <Typography paragraph>
            We can also explain more about your legal rights as we know this stuff can be confusing
            and difficult to follow.
          </Typography>
          <PrivacyRight
            name="Request access to your personal information"
            description={
              <>
                <Typography paragraph>
                  You may have heard of your right to make subject access requests. You can ask for
                  a copy of the information that an organisation holds about you and for other
                  information about what they are doing with it.
                </Typography>
                <Typography paragraph>
                  So you can ask for a copy of the personal information that we hold about you in
                  Compass so that you can check that we are doing what we said we would.
                </Typography>
              </>
            }
          />
          <PrivacyRight
            name="Request correction of the personal information that we hold about you"
            description={
              <>
                <Typography paragraph>
                  If you think that the information we hold about you is wrong, then you can ask us
                  to correct it.
                </Typography>
                <Typography paragraph>
                  You may also think that the information we hold is incomplete and only tells part
                  of the story and you can ask us to add information to complete the story.
                </Typography>
              </>
            }
          />
          <PrivacyRight
            name="Request erasure of your personal information"
            description={
              <>
                <Typography paragraph>
                  In some circumstances you can ask us to delete information about you from Compass.
                </Typography>
                <Typography paragraph>
                  We will consider whether we have a good reason to hold onto it before we decide
                  whether we must delete it and we will let you know what decision we make and why.
                </Typography>
              </>
            }
          />
          <PrivacyRight
            name="Request the restriction of processing of your personal information"
            description="If you were concerned that the information we had was not correct or incomplete,
                  then you could ask us to stop doing anything with it until this issue was
                  resolved."
          />
          <PrivacyRight
            name="Your right to data portability"
            description="This is a very technical right, but you can ask us to transfer the personal information
            you gave us to another organisation, or directly to you."
          />
          <PrivacyRight
            name="Object to the processing of your personal information"
            description="You can ask us to stop processing your information because you feel that it is causing
            you problems or upsetting you and we would consider your request carefully. We will
            consider whether we have a good reason to keep using it and we will let you know what
            decision we make about that and why."
          />
        </div>
        <div className="home-section">
          <Typography variant="h2" gutterBottom id="queries">
            Queries or concerns
          </Typography>
          <Typography paragraph>
            Please contact us using the{' '}
            <Link component={HashLink} to={{pathname: '/privacy', hash: '#contact'}}>
              contact details above
            </Link>{' '}
            to make a request or to raise any concerns you have about how the personal data gathered
            through Compass is being used.
          </Typography>
          <Typography paragraph>
            You can also contact the Information Commissioner’s Office if you have concerns and
            their contact details can be found here:{' '}
            <Link href="https://www.ico.org.uk/concerns" target="_blank" rel="noreferrer">
              www.ico.org.uk/concerns
            </Link>
          </Typography>
        </div>
      </Container>
    </>
  );
};

export default YoungPersonPrivacyNotice;
