import React from 'react';

import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import {CircularProgress, Typography} from '@mui/material';

import useYoungPersonTransitionTypes from '../../Queries/useYoungPersonTransitionTypes';

const TransitionTypePage = ({transitionType, setTransitionType}) => {
  const {data: transitionTypes, isLoading} = useYoungPersonTransitionTypes();
  return (
    <>
      <Typography variant="h2" gutterBottom>
        What is the next thing you might need help with?
      </Typography>
      <Typography paragraph gutterBottom>
        If you are not sure what you might need help with, just choose 'Still working out what
        matters'.
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <RadioGroup
          aria-label="transition-type"
          name="transition-type"
          value={transitionType}
          onChange={(event) => setTransitionType(event.target.value)}>
          {transitionTypes.map((item) => (
            <FormControlLabel
              key={item.id}
              label={item.name}
              value={item.name}
              control={<Radio color="secondary" />}
            />
          ))}
        </RadioGroup>
      )}
    </>
  );
};

export default TransitionTypePage;
