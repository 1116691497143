import React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Typography} from '@mui/material';

const AboutYouIdentifiesAsDisabled = ({identifiesAsDisabled, onChange}) => (
  <>
    <Typography variant="h3" gutterBottom>
      Do you identify as having additional support needs or disability?
    </Typography>
    <Typography paragraph variant="body2">
      Choose one of the options.
    </Typography>
    <RadioGroup
      aria-label="identifies-as-disabled-radio-group"
      name="identifies-as-disabled-radio-group"
      value={identifiesAsDisabled}
      onChange={(event) => onChange(event.target.value)}>
      <FormControlLabel label="Yes" value="Yes" control={<Radio color="secondary" />} />
      <FormControlLabel label="No" value="No" control={<Radio color="secondary" />} />
      <FormControlLabel
        label="I do not know"
        value="I do not know"
        control={<Radio color="secondary" />}
      />
    </RadioGroup>
  </>
);

export default AboutYouIdentifiesAsDisabled;
